
import { Logo } from "assets/images/imagesList";
import { Grid } from "@mui/material";

export function HeaderLogo() {
  return (
    <Grid item>
      <img src={Logo} width="119px" height="111" alt="logo" style={{marginLeft: 117, marginRight: 117}}/>
    </Grid>
  );
}