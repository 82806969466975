import { Grid } from "@mui/material";

export function Age(props) {
    return (
        <Grid item style={{
            fontSize: 18, 
            marginTop: 12, 
            padding: "13px 33px", 
            borderRadius: 20, 
            boxShadow: "1px 2px #ececec",
            backgroundColor: "white", 
            fontFamily: "Museo700"
        }}>
            {props.value} yrs old
        </Grid>
    );
}