import React, { useState, useEffect } from "react";
import './App.css';
import { Start } from './pages/Start';
import { PerformanceProfile } from "./pages/PerformanceProfile";
import { SpecifyDNA } from "pages/SpecifyDNA";
import { Wearables } from "pages/Wearables";
import { Insights } from "pages/Insights";
import { Program } from "pages/Program";

function App() {
  const [currentStep, setCurrentStep] = useState({ix: 0, name: undefined});
  const [selectedWearable, setSelectedWearable] = useState(null);
  const [hasDNAFile, setHasDNAFile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const onNext = () => {
    setCurrentStep({ix: currentStep.ix + 1, name: currentStep.name});
  }

  const onPrevious = () => {
    setCurrentStep({ix: currentStep.ix - 1, name: currentStep.name});
  }

  return (
    currentStep.ix === 0 
    ? <Start onProfileSelected={(name) => setCurrentStep({ix: currentStep.ix + 1, name: name})} /> 
    : currentStep.ix === 1 
    ? <PerformanceProfile 
        profile={currentStep.name} 
        onContinue={() => onNext()}
        onBack={() => onPrevious()}
      /> 
    : currentStep.ix === 2
    ? <SpecifyDNA 
        profile={currentStep.name} 
        onContinue={(mode) => {
          setHasDNAFile(mode === "withdna");
          onNext();
        }}
        onBack={() => onPrevious()}
      />
    : currentStep.ix === 3
    ? <Wearables 
        profile={currentStep.name} 
        onContinue={(wearable) => {
          setSelectedWearable(wearable);
          onNext();
        }}
        onBack={() => onPrevious()}
        wearableToSelected={selectedWearable}
      />
    : currentStep.ix === 4
    ? <Insights
        profile={currentStep.name} 
        onContinue={() => onNext()}
        onBack={() => onPrevious()}
        hasDNAFile={hasDNAFile}
      />
    : <Program  
        profile={currentStep.name} 
        onBack={() => onPrevious()}
      />
  );
}

export default App;
