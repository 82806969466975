import React from "react";
import { Grid, useMediaQuery } from "@mui/material";

export function ProgramSchedule(props) {
  const { schedule } = props;
  const isMobile = useMediaQuery("(max-width:900px)");

  return (
    <Grid container direction="row">
      {schedule.map((day, ix) => {
        return (
          <Grid item xs={isMobile ? 12 : 2} key={"day" + ix} style={{marginRight: 30, marginTop: 30, fontFamily: "Museo500"}}>
            <Grid container direction="column">
              <Grid item style={{fontSize: 30, fontFamily: "Museo700"}}>
                {day.name}
              </Grid>
              {day.items.map((item, ix) => {
                return (
                  <Grid item key={"item" + ix} style={{marginTop: item.title ? 16 : 8, fontFamily: item.title ? "Museo700" : "Museo500"}}>
                    {item.value}
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        )
      })}
      
    </Grid>
  );
}