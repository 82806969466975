import { Grid } from '@mui/material';
import { HealthInfo } from 'components/HealthInfo';

export function HealthTable(props) {
  const { scoreTitle, score, scoreText, healthData } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      {score && (
        <>
          <Grid item style={{ fontSize: 16, fontWeight: 700 }}>
            {scoreTitle}
          </Grid>

          <div
            style={{
              width: 48,
              height: 48,
              marginTop: 20,
              borderRadius: 50,
              backgroundColor: '#ADD429',
              textAlign: 'center',
              fontSize: 22,
              lineHeight: '48px',
              fontWeight: 500,
              color: '#fff',
            }}
          >
            {score}
          </div>
          <Grid item style={{ fontSize: 16, fontWeight: 500, marginTop: 5 }}>
            {scoreText}
          </Grid>
        </>
      )}
      {healthData &&
        healthData.map((healthItem) => {
          return (
            <Grid container style={{ marginBottom: 10 }}>
              {healthItem.title && (
                <Grid
                  item
                  xs={12}
                  style={{ fontSize: 16, fontWeight: 700, textAlign: 'center' }}
                >
                  {healthItem.title}
                </Grid>
              )}
              {healthItem.data &&
                healthItem.data.map((item) => {
                  return (
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                      <HealthInfo text={item.text} value={item.value} />
                    </Grid>
                  );
                })}
            </Grid>
          );
        })}
    </Grid>
  );
}
