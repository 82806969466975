import { Grid } from '@mui/material';
import { FlippableGoals } from './FlippableGoals';

export function HealthTile(props) {
  const { image, goal, front, back } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        width: 300,
        height: 380,
        borderRadius: 20,
        border: '1px solid #ececec',
        backgroundColor: 'white',
        padding: 10,
        cursor: 'pointer',
        fontFamily: 'Museo700',
      }}
    >
      <Grid item style={{ marginTop: 20, height: 52 }}>
        <img src={image} alt="tile illustration" />
      </Grid>
      <Grid item style={{ fontSize: 22, marginTop: 5, textAlign: 'center' }}>
        {goal}
      </Grid>
      <Grid item style={{ marginTop: 10 }}>
        <FlippableGoals front={front} back={back} />
      </Grid>
    </Grid>
  );
}
