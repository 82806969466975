import { Grid } from '@mui/material';

export function GoalTargets(props) {
  const { targets } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item style={{ textAlign: 'center', fontSize: 16, marginTop: 10 }}>
        {'GOAL'}
      </Grid>
      {targets.map((target, ix) => {
        return (
          <Grid item style={{ fontSize: 14, marginTop: 5 }} key={ix}>
            <Grid container direction="column">
              <Grid
                item
                style={{ textAlign: 'center', fontSize: 40, color: '#31B2CF' }}
              >
                {target.value}
              </Grid>
              <Grid item style={{ fontSize: 16 }}>
                {target.description}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
