import { Grid } from "@mui/material";

export function HeaderCircle(props) {
  const { values } = props;

  return (
      <div style={{
        height: 300,
        width: 300,
        borderRadius: 150, 
        boxShadow: "1px 2px #ececec",
        backgroundColor: "white", 
        fontFamily: "Museo700"
      }}>
          <Grid container direction="column" justifyContent={"center"} alignItems="center" style={{height: 300, width: 300}}>
            <Grid item style={{fontSize: 50, color: "#0BB4DD"}}>
              {values.value}
            </Grid>
            <Grid item style={{fontSize: 20}}>
              {values.text}
            </Grid>
            <Grid item style={{padding: 20, textAlign: "center", fontSize: 25}}>
              {values.description}
            </Grid>
          </Grid>
      </div>
  );
}