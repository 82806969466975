import { Grid, useMediaQuery } from '@mui/material';
import { ContinueButtonSection } from 'components/ContinueButtonSection';
import { Header } from 'components/Header';
import { WhiteArrow } from 'assets/images/imagesList';
import { isDan } from 'nameHelper';

export function SpecifyDNA(props) {
  const { onBack, onContinue, profile } = props;
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <div>
      <Header
        onBack={() => onBack && onBack()}
        title="DNA data upload"
        additionalText=""
        profile={profile}
      />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 55, marginBottom: 85 }}
      >
        <Grid item>
          <Grid container direction="column">
            <Grid
              item
              style={{ fontSize: 45, fontFamily: 'Museo500', paddingLeft: 10 }}
            >
              DNA data upload
            </Grid>
            <Grid
              item
              style={{
                maxWidth: isMobile ? '90vw' : 500,
                fontSize: 24,
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: isMobile ? 10 : 50,
              }}
            >
              {isDan(profile)
                ? 'In this step, Dan can choose whether or not to use DNA data to personalize his exercise regimen and recommendations. If Dan doesn’t have DNA data or does not want to use DNA data, he can still use the information from the questionnaire and his wearable to get personalized coaching.'
                : 'In this step, Sophie can choose whether or not to use DNA data to personalize her diet regimen and nutrition recommendations. If Sophie doesn’t have DNA data or does not want to use DNA data, she can still use the information from the questionnaire to get some personalized recommendations.'}
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: 50, fontFamily: 'Museo700' }}>
          <Grid container direction="column">
            <Grid
              item
              style={{
                width: isMobile ? '90vw' : 600,
                height: isMobile ? 90 : 130,
                backgroundColor: '#31B2CF',
                opacity: 0.58,
                borderRadius: 10,
                padding: isMobile ? 20 : 40,
                color: 'white',
                cursor: 'pointer',
              }}
              onClick={() => onContinue && onContinue()}
            >
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column">
                    <Grid
                      item
                      style={{
                        fontSize: isMobile ? 20 : 30,
                        textTransform: 'uppercase',
                      }}
                    >
                      I don&apos;t have DNA data
                    </Grid>
                    <Grid item style={{ fontSize: 20 }}>
                      Proceed with out it
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ marginTop: 5 }}>
                  <img
                    src={WhiteArrow}
                    alt="illustration for click to proceed without DNA"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                width: isMobile ? '90vw' : 600,
                height: isMobile ? 90 : 130,
                backgroundColor: '#31B2CF',
                borderRadius: 10,
                marginTop: 15,
                padding: isMobile ? 20 : 40,
                color: 'white',
                cursor: 'pointer',
              }}
              onClick={() => onContinue && onContinue('withdna')}
            >
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column">
                    <Grid
                      item
                      style={{
                        fontSize: isMobile ? 20 : 30,
                        textTransform: 'uppercase',
                      }}
                    >
                      Upload DNA Data
                    </Grid>
                    <Grid item style={{ fontSize: isMobile ? 11 : 20 }}>
                      (from 23andMe, AncestryDNA, or Other providers)
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ marginTop: 5 }}>
                  <img
                    src={WhiteArrow}
                    alt="illustration for click to proceed with DNA"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <ContinueButtonSection
        onContinue={() => onContinue && onContinue('withdna')}
      /> */}
    </div>
  );
}
