import { Grid } from '@mui/material';
import { FlipIndicator } from 'assets/images/imagesList';

export function InsightTile(props) {
  const { title, centerValue, value, color } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      style={{ fontFamily: 'Museo700', height: 'inherit' }}
    >
      <Grid item style={{ fontSize: 18, textAlign: 'center' }}>
        {title}
      </Grid>
      <Grid item style={{ fontSize: 40, marginTop: 20, color: color }}>
        {centerValue}
      </Grid>
      <Grid item style={{ fontSize: 26, marginTop: 20, textAlign: 'center' }}>
        {value}
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <img
          src={FlipIndicator}
          height={20}
          alt="illustration for flip indicator"
          style={{ float: 'right', marginTop: -25, marginRight: -15 }}
        />
      </Grid>
    </Grid>
  );
}
