import { Grid } from "@mui/material";

export function DiscoverButton(props) {
  const { name, onClick, color } = props;

  return (
    <Grid 
      item 
      style={{
        textDecoration: "uppercase", 
        fontSize: 24, 
        backgroundColor: color, 
        padding: "10px 20px", 
        borderRadius: 100, 
        cursor: "pointer", 
        marginTop: 12,
        fontFamily: "Museo700"
      }}
      onClick={() => onClick && onClick()}
    >
      Discover {name}&apos;s journey
    </Grid>
  );
}