import React from 'react';
import { Grid, Typography } from '@mui/material';

import ListContent from 'components/ListContent';

export default function Instructions(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        {props.data && props.data.recipeUrl != null && (
          <Grid item xs={12} style={{ margin: '20px 0' }}>
            <Typography
              variant="body1"
              style={{ textAlign: 'left', margin: '0 5%' }}
            >
              <a href={props.data.recipeUrl} target="_blank" rel="noreferrer">
                {props.data.displayName}
              </a>
            </Typography>
          </Grid>
        )}
        {props.data.recipeUrl == null && props.data.length > 0 && (
          <ListContent data={props.data} />
        )}
      </Grid>
    </Grid>
  );
}
