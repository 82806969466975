import {
  ArrowLeft,
  HeaderBackground,
  HeaderBackgroundSophie,
} from 'assets/images/imagesList';
import { Grid, useMediaQuery } from '@mui/material';
import { HeaderLogo } from './HeaderLogo';
import { Dan, Sophie } from 'assets/images/imagesList';
import { Age } from './Age';
import { isDan } from 'nameHelper';
import { HeaderCircle } from './HeaderCircle';
import { HeaderSquare } from './HeaderSquare';

export function Header(props) {
  const {
    onBack,
    title,
    additionalText,
    profile,
    infoCircleValues,
    infoSquareOption,
  } = props;
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <div
      style={{
        width: '100%',
        height: 360,

        background: `url(${
          isDan(profile) ? HeaderBackground : HeaderBackgroundSophie
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 25, cursor: 'pointer' }}
            onClick={() => onBack && onBack()}
          >
            <Grid item style={{ marginRight: 15, marginLeft: 15 }}>
              <img
                src={ArrowLeft}
                width="35"
                height="21"
                alt="illustration for going back"
              />
            </Grid>
            {!isMobile && (
              <Grid
                item
                style={{ fontSize: 20, marginTop: 0, fontFamily: 'Museo700' }}
              >
                BACK
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            fontSize: 30,
            marginTop: 25,
            fontFamily: 'Museo500',
            maxWidth: '80vw',
            textAlign: 'center',
          }}
        >
          {title}
        </Grid>
        <Grid item>{!isMobile && <HeaderLogo />}</Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 46 }}
      >
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container direction="row">
                <Grid item>
                  <img
                    src={isDan(profile) ? Dan : Sophie}
                    width={isMobile ? '150px' : '200px'}
                    alt="illustration of a persona"
                  />
                </Grid>
                <Grid item style={{ fontSize: 38, marginLeft: 16 }}>
                  <Grid container direction="column">
                    <Grid item>{profile}</Grid>
                    <Grid item>
                      <Age value={isDan(profile) ? 41 : 34} />
                    </Grid>
                  </Grid>
                </Grid>
                {!isMobile && (
                  <Grid
                    item
                    style={{
                      width: 500,
                      fontSize: 24,
                      marginLeft: 140,
                      fontFamily: 'Museo300',
                    }}
                  >
                    {additionalText}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!isMobile && infoCircleValues && (
              <Grid item style={{ marginLeft: -250, marginTop: -75 }}>
                <HeaderCircle values={infoCircleValues} />
              </Grid>
            )}
            {!isMobile && infoSquareOption && (
              <Grid item style={{ marginLeft: -550, marginTop: -45 }}>
                <HeaderSquare option={infoSquareOption} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
