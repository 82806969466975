import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Grid, useMediaQuery } from '@mui/material';
import { HighPredisposition, HighRisk, LowPredisposition, LowRisk, MediumPredisposition, MediumRisk } from "assets/images/imagesList";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={null}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: 'none',
}));

export function DNAReport(props) {
  const { reports } = props;
  const [expanded, setExpanded] = React.useState('panel1');
  const isMobile = useMediaQuery("(max-width:900px)");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {reports?.map((report, ix) => {
        return (
          <Accordion expanded={expanded === "panel" + (ix + 1)} onChange={handleChange("panel" + (ix + 1))}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography style={{fontSize: 25, fontFamily: "Museo700"}}>{report.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column" style={{padding: 10}}>
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item sm={12} md={4} style={{width: "100%"}}>
                      <Grid container direction="column">
                        <Grid item style={{border: "1px solid #ececec", height: 18, backgroundColor: "#D89EAB", borderTopLeftRadius: 8, borderTopRightRadius: 8}}>
                        </Grid>
                        <Grid item style={{borderLeft: "1px solid #ececec", borderRight: "1px solid #ececec", borderBottom: "1px solid #ececec", height: 123, fontFamily: "Museo500"}}>
                          <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{padding: 30}}>
                            <Grid item style={{fontSize: 20}}>My Genetic Risks</Grid>
                            <Grid item style={{fontSize: 34}}>{report.risks}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid 
                      item 
                      sm={12} 
                      md={4} 
                      style={{
                        width: "100%", 
                        paddingLeft: isMobile ? 0 : 30, 
                        paddingRight: isMobile ? 0 : 30, 
                        fontFamily: "Museo500",
                        marginTop: isMobile ? 20 : 0
                      }}
                    >
                      {report.riskyTraits.map((trait, ix) => {
                        return (
                          <Grid container direction="row" justifyContent="space-between" alignItems="center"
                            key={ix} style={{marginBottom: 10}}
                          >
                            <Grid item xs={11}>
                              {trait.name}
                            </Grid>
                            <Grid item xs={1} style={{paddingLeft: 10}}>
                              <img src={trait.image} alt="illustration for trait risk" />
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                    <Grid 
                      item 
                      sm={12} 
                      md={4} 
                      style={{
                        paddingLeft: isMobile ? 0 : 30, 
                        paddingRight: isMobile ? 0 : 30, 
                        fontFamily: "Museo500", 
                        width: "100%",
                        marginTop: isMobile ? 20 : 0
                      }}>
                      <Grid container direction="column">
                        <Grid item>
                          My Genetic Risk Levels
                        </Grid>
                        <Grid item style={{marginTop: 5}}>
                          <Grid container direction="row" justifyContent="flex-start">
                            <Grid item>
                              <img src={LowRisk} alt="illustration for low risk" />
                            </Grid>
                            <Grid item style={{marginLeft: 10}}>
                              Low
                            </Grid>
                            <Grid item style={{marginLeft: 20}}>
                              <img src={MediumRisk} alt="illustration for Medium risk" />
                            </Grid>
                            <Grid item style={{marginLeft: 10}}>
                              Medium
                            </Grid>
                            <Grid item style={{marginLeft: 20}}>
                              <img src={HighRisk} alt="illustration for High risk" />
                            </Grid>
                            <Grid item style={{marginLeft: 10}}>
                              High
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid> 
                </Grid>
                <Grid item style={{marginTop: 60}}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item sm={12} md={4} style={{width: "100%"}}>
                      <Grid container direction="column">
                        <Grid item style={{border: "1px solid #ececec", height: 18, backgroundColor: "#9DB187", borderTopLeftRadius: 8, borderTopRightRadius: 8}}>

                        </Grid>
                        <Grid item style={{borderLeft: "1px solid #ececec", borderRight: "1px solid #ececec", borderBottom: "1px solid #ececec", height: 123, fontFamily: "Museo500"}}>
                          <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ padding: 30}}>
                            <Grid item style={{fontSize: 20}}>My Genetic Strength</Grid>
                            <Grid item style={{fontSize: 34}}>{report.strengths}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid 
                      item 
                      sm={12} 
                      md={4} 
                      style={{
                        width: "100%",
                        paddingLeft: isMobile ? 0 : 30, 
                        paddingRight: isMobile ? 0 : 30, 
                        fontFamily: "Museo500",
                        marginTop: isMobile ? 20 : 0
                      }}>
                        {report.strengthTraits.map((trait, ix) => {
                          return (
                            <Grid 
                              container direction="row" 
                              justifyContent="space-between" 
                              alignItems="center"
                              key={ix} 
                              style={{marginBottom: 10}}
                            >
                              <Grid item xs={11}>
                                {trait.name}
                              </Grid>
                              <Grid item xs={1} style={{paddingLeft: 10}}>
                                <img src={trait.image} alt="illustration for trait risk" />
                              </Grid>
                            </Grid>
                          )
                        })}
                    </Grid>
                    <Grid 
                      item 
                      sm={12} 
                      md={4} 
                      style={{
                        paddingLeft: isMobile ? 0 : 30, 
                        paddingRight: isMobile ? 0 : 30, 
                        fontFamily: "Museo500",
                        width: "100%",
                        marginTop: isMobile ? 20 : 0
                      }}>
                      <Grid container direction="column">
                        <Grid item>
                          My Genetic Strengths Levels
                        </Grid>
                        <Grid item style={{marginTop: 5}}>
                          <Grid container direction="row" justifyContent="flex-start">
                            <Grid item>
                              <img src={LowPredisposition} alt="illustration for low predisposition" />
                            </Grid>
                            <Grid item style={{marginLeft: 10}}>
                              Low
                            </Grid>
                            <Grid item style={{marginLeft: 20}}>
                              <img src={MediumPredisposition} alt="illustration for Medium predisposition" />
                            </Grid>
                            <Grid item style={{marginLeft: 10}}>
                              Medium
                            </Grid>
                            <Grid item style={{marginLeft: 20}}>
                              <img src={HighPredisposition} alt="illustration for High predisposition" />
                            </Grid>
                            <Grid item style={{marginLeft: 10}}>
                              High
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> 
              </Grid>     
            </AccordionDetails>
          </Accordion>
      )})}
    </div>
  );
}
