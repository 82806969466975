import React from 'react';

import { Grid, Typography, useMediaQuery } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

export default function Ingredients(props) {
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <div style={{ width: '100%' }}>
      {props.data &&
        props.data.map((item, ix) => (
          <Grid
            container
            direction="row"
            alignItems="start"
            key={ix}
            style={{ width: '100%', marginBottom: props.addMargin && '20px' }}
          >
            <CircleIcon
              style={{
                marginTop: '5px',
                marginRight: '16px',
                fontSize: 9,
                color: '#EDDCD5',
              }}
            />

            <Grid item xs>
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Lato-Regular',
                  fontSize: !isMobile ? '16px' : '14px',
                  lineHeight: !isMobile ? '20px' : '18px',
                  textAlign: 'left',
                  color: '#342f38',
                }}
              >
                {item}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </div>
  );
}
