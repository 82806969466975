import { Grid, useMediaQuery } from '@mui/material';
import { Check } from 'assets/images/imagesList';

export function AnswersWithCheckMark(props) {
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      justifyContent="center"
      alignItems="flex-start"
    >
      {props.data.map((answersItem, ix) => {
        return (
          <Grid item style={{ width: 300, marginTop: 40 }} key={'i' + ix}>
            <div style={{ marginBottom: 10 }}>
              <span style={{ fontSize: 25 }}>{answersItem.title}</span>
            </div>
            <Grid container direction="column">
              {answersItem.answers?.map((factor, ix) => {
                return (
                  <Grid item key={'f' + ix} style={{ marginTop: 15 }}>
                    <Grid container direction="row">
                      <Grid
                        item
                        style={{
                          width: 25,
                          height: 25,
                          marginTop: -2,
                          marginRight: 10,
                          backgroundColor: factor.checked ? '#46B2CF' : 'white',
                          borderRadius: 30,
                        }}
                      >
                        {factor.checked ? (
                          <img
                            src={Check}
                            alt="goal completed illustration"
                            style={{ marginLeft: 2, marginTop: 4 }}
                          />
                        ) : null}
                      </Grid>
                      <Grid item style={{ maxWidth: 250 }}>
                        {factor.name}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
