import React, { useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Header } from 'components/Header';
import { ProgramSchedule } from './ProgramSchedule';
import { ProgramVideos } from 'components/ProgramVideos';
import { LNTab, LNTabs, TabPanel } from 'components/LNTabs';
import { HeaderSquare } from 'components/HeaderSquare';
import RecipeDetails from 'components/RecipeDetails';
import { ProgramNutrition } from './ProgramNutrition';

import { isDan } from 'nameHelper';
import { recipes } from 'recipesHelper';
import {
  danExerciseVideos1,
  danExerciseVideos2,
  sophieExerciseVideos,
  sophieNutritionVideos,
  sophiePregnancyVideos,
  getDanExerciseSchedule,
} from 'programHelper';

export function Program(props) {
  const { onBack, profile } = props;
  const [option, setOption] = useState(isDan(profile) ? 1 : 3);
  const [tabValue, setTabValue] = React.useState(0);
  const [showDetails, setShowDetails] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');
  const [selectedRecipe, setSelectedRecipe] = React.useState(recipes[0]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (isDan(profile)) {
      if (newValue === 0) {
        setOption(1);
      } else {
        setOption(2);
      }
    } else {
      if (newValue === 0) {
        setOption(3);
      } else if (newValue === 1) {
        setOption(4);
      } else {
        setOption(5);
      }
    }
  };

  const handleBack = () => {
    if (showDetails) {
      setShowDetails(false);
    } else {
      onBack && onBack();
    }
  };

  const handleRecipeClick = (id) => {
    setShowDetails(true);
    setSelectedRecipe(recipes[id]);
  };

  const getVideos = (option) => {
    return option === 1
      ? danExerciseVideos1
      : option === 2
      ? danExerciseVideos2
      : option === 3
      ? sophieNutritionVideos
      : option === 4
      ? sophiePregnancyVideos
      : option === 5
      ? sophieExerciseVideos
      : [];
  };

  return (
    <div>
      <Header
        onBack={handleBack}
        title={
          (isDan(profile) ? 'Dan' : 'Sophie') +
          '’s Recommended Precision Program'
        }
        additionalText=""
        profile={profile}
        infoSquareOption={isMobile || showDetails ? null : option}
      />

      {showDetails ? (
        <div style={{ zIndex: -1 }}>
          <RecipeDetails
            r={selectedRecipe}
            recipe={selectedRecipe.recipe}
            meal={selectedRecipe.meal}
            numberOfServings={selectedRecipe.numOfServings}
            parentComponent="meal_plan"
          />
        </div>
      ) : (
        <div
          style={{
            width: isMobile ? 'auto' : 1200,
            padding: 10,
            margin: 'auto',
            marginTop: 55,
            marginBottom: 85,
            color: 'black',
          }}
        >
          {!isMobile && (
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              style={{ minHeight: 200 }}
            >
              {isDan(profile) && (
                <Grid
                  item
                  style={{
                    fontSize: 24,
                    cursor: 'pointer',
                    fontWeight: option === 1 ? 700 : 300,
                  }}
                  onClick={() => setOption(1)}
                >
                  Program 1<br />
                  Combined Cardio-Resistance <br />
                  (Level 3)
                </Grid>
              )}
              {isDan(profile) && (
                <Grid
                  item
                  style={{
                    fontSize: 24,
                    cursor: 'pointer',
                    marginTop: 20,
                    fontWeight: option === 2 ? 700 : 300,
                  }}
                  onClick={() => setOption(2)}
                >
                  Program 2<br />
                  Ramp-up Power Walk and <br />
                  Intro to Fitness Program <br />
                  (Level 1)
                </Grid>
              )}
              {!isDan(profile) && (
                <Grid
                  item
                  style={{
                    fontSize: 24,
                    cursor: 'pointer',
                    fontWeight: option === 3 ? 700 : 300,
                  }}
                  onClick={() => setOption(3)}
                >
                  Nutrition Program
                </Grid>
              )}
              {!isDan(profile) && (
                <Grid
                  item
                  style={{
                    fontSize: 24,
                    cursor: 'pointer',
                    marginTop: 20,
                    fontWeight: option === 4 ? 700 : 300,
                  }}
                  onClick={() => setOption(4)}
                >
                  Pregnancy Program
                </Grid>
              )}
              {!isDan(profile) && (
                <Grid
                  item
                  style={{
                    fontSize: 24,
                    cursor: 'pointer',
                    marginTop: 20,
                    fontWeight: option === 5 ? 700 : 300,
                  }}
                  onClick={() => setOption(5)}
                >
                  Exercise Program
                </Grid>
              )}
            </Grid>
          )}

          {!isMobile && (
            <div>
              {isDan(profile) && (
                <div style={{ marginTop: 70 }}>
                  <ProgramSchedule schedule={getDanExerciseSchedule(option)} />
                </div>
              )}
              {!isDan(profile) && option === 3 && (
                <div style={{ marginTop: 70 }}>
                  <Typography
                    style={{
                      textTransform: 'uppercase',
                      fontSize: 20,
                      fontHeight: 26,
                      fontWeight: 500,
                      marginBottom: 20,
                    }}
                  >
                    Start with these meals
                  </Typography>
                  <ProgramNutrition
                    recipes={recipes}
                    onRecipeClick={handleRecipeClick}
                  />
                </div>
              )}
              <div style={{ marginTop: 70 }}>
                <Typography
                  style={{
                    textTransform: 'uppercase',
                    fontSize: 20,
                    fontHeight: 26,
                    fontWeight: 500,
                    marginBottom: 20,
                  }}
                >
                  {isDan(profile)
                    ? 'Start with these exercises'
                    : 'Start with these healthy videos'}
                </Typography>
                <ProgramVideos videos={getVideos(option)} />
              </div>
            </div>
          )}
          {isMobile && (
            <div>
              <LNTabs
                value={tabValue}
                onChange={handleChange}
                textColor="#31B2CF"
                indicatorColor="#31B2CF"
                aria-label="programs tabs"
                centered
                variant="fullWidth"
              >
                <LNTab
                  value={0}
                  label={isDan(profile) ? 'PROGRAM 1' : 'NUTRITION'}
                />
                <LNTab
                  value={1}
                  label={isDan(profile) ? 'PROGRAM 2' : 'PREGNANCY'}
                />
                {!isDan(profile) && <LNTab value={2} label={'EXERCISE'} />}
              </LNTabs>

              {[0, 1, 2].map((index) => {
                return (
                  <TabPanel value={tabValue} index={index} key={index}>
                    <HeaderSquare option={option} />

                    {isDan(profile) && (
                      <div style={{ marginTop: 70 }}>
                        <ProgramSchedule
                          schedule={getDanExerciseSchedule(option)}
                        />
                      </div>
                    )}

                    {!isDan(profile) && option === 3 && (
                      <div style={{ marginTop: 70 }}>
                        <Typography
                          style={{
                            textTransform: 'uppercase',
                            fontSize: 20,
                            fontHeight: 26,
                            fontWeight: 500,
                            marginBottom: 20,
                            textAlign: 'center',
                          }}
                        >
                          Start with these meals
                        </Typography>
                        <ProgramNutrition
                          recipes={recipes}
                          onRecipeClick={handleRecipeClick}
                        />
                      </div>
                    )}

                    <div style={{ marginTop: 70 }}>
                      <Typography
                        style={{
                          textTransform: 'uppercase',
                          fontSize: 22,
                          fontHeight: 26,
                          fontWeight: 500,
                          marginBottom: 20,
                          textAlign: 'center',
                        }}
                      >
                        {isDan(profile)
                          ? 'Start with these exercises'
                          : 'Start with these healthy videos'}
                      </Typography>
                      <ProgramVideos videos={getVideos(option)} />
                    </div>
                  </TabPanel>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
