import LinearProgress from './LinearProgress';
import { Grid, Typography } from '@mui/material';

export function HealthInfo(props) {
  const { text, value, max } = props;
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Typography
            variant="body1"
            style={{ fontSize: '12px', textAlign: 'left' }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            style={{ fontSize: '12px', textAlign: 'right' }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
      <LinearProgress
        backgroundColor="#e0e0e0"
        borderRadius={12}
        height={12}
        progressColor="#45AEC1"
        value={value}
      />
    </>
  );
}
