import { Grid } from '@mui/material';
import { ContinueButtonSection } from 'components/ContinueButtonSection';
import { AnswersList } from 'components/AnswersList';
import { AnswersWithCheckMark } from 'components/AnswersWithCheckMark';
import { Header } from 'components/Header';
import { isDan } from 'nameHelper';

export function PerformanceProfile(props) {
  const { onBack, onContinue, profile } = props;

  const getLifestyleFactors = () => {
    if (isDan(profile))
      return [
        { name: 'Smoking', value: 'No' },
        { name: 'Drinks', value: 'Social' },
        { name: 'Diet', value: 'Pescatarian' },
        { name: 'Exercise', value: 'Sometimes' },
        { name: 'Sleep', value: 'Moderate' },
        { name: 'Stress', value: 'Moderate' },
      ];
    else
      return [
        { name: 'Smoking', value: 'No' },
        { name: 'Drinks', value: 'No' },
        { name: 'Diet', value: 'Vegetarian' },
        { name: 'Exercise', value: 'Regular' },
        { name: 'Sleep', value: 'Moderate' },
        { name: 'Stress', value: 'Low' },
      ];
  };

  const getGeneral = () => {
    if (isDan(profile))
      return [
        { name: 'Height', value: '5’ 10”' },
        { name: 'Weight', value: '198 pounds' },
        { name: 'Living', value: 'Sub-urban' },
      ];
    else
      return [
        { name: 'Height', value: '5’ 6' },
        { name: 'Weight', value: '143 pounds' },
        { name: 'Living', value: 'Urban' },
      ];
  };

  const getHealthConditions = () => {
    if (isDan(profile)) return [{ name: 'Hypertension', value: '' }];
    else
      return [
        { name: 'Pregnancy', value: '' },
        { name: 'Gestational Diabetes Risk (Elevated)', value: '' },
      ];
  };

  const getPerformance = () => {
    if (isDan(profile)) {
      return [
        { name: 'Weight Loss', checked: true },
        { name: 'Longevity', checked: false },
        { name: 'Energy and Mood', checked: true },
        { name: 'Condition Management', checked: false },
        { name: 'Bodybuilding/Toning', checked: false },
        { name: 'General Fitness', checked: true },
        { name: 'Competitive Fitness', checked: false },
      ];
    } else {
      return [
        { name: 'Weight Loss', checked: false },
        { name: 'Longevity', checked: false },
        { name: 'Energy and Mood', checked: true },
        { name: 'Micro and Macro Nutrient balance', checked: true },
        { name: 'Supplementation', checked: true },
        { name: 'General Heathy Eating', checked: false },
      ];
    }
  };

  const getPreferences = () => {
    if (isDan(profile))
      return [
        { name: 'Gym', checked: true },
        { name: 'Home', checked: false },
        { name: 'Outdoor', checked: true },
        { name: 'Office', checked: false },
      ];
    else
      return [
        { name: 'Vegetarian', checked: true },
        { name: 'Gluten-free', checked: true },
        { name: 'Low-carb', checked: false },
        { name: 'Dairy Free', checked: false },
        { name: 'Food allergies', checked: false },
      ];
  };

  const getTypeOfExercises = () => {
    if (isDan(profile))
      return [
        { name: 'HIIT', checked: true },
        { name: 'Yoga/Pilates', checked: false },
        { name: 'Cardio', checked: true },
        { name: 'Power', checked: true },
        { name: 'Walking', checked: true },
        { name: 'Running/Endurance', checked: false },
      ];
    else
      return [
        { name: 'Goal: 30 min/day', checked: true },
        { name: 'Preference: Yoga, Cardio', checked: true },
      ];
  };

  return (
    <div>
      <Header
        onBack={() => onBack && onBack()}
        additionalText={
          isDan(profile)
            ? 'We start by asking Dan about his general well-being, understanding his habits, and assessing his exercise preferences. These are his responses.'
            : 'We start by asking Sophie about her general well-being, understanding her habits, and assessing her diet  preferences.'
        }
        title={isDan(profile) ? 'Precision Performance Profile' : ''}
        profile={profile}
      />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 55, fontFamily: 'Museo700', marginBottom: 85 }}
      >
        <Grid item>
          <AnswersList
            data={[
              { title: 'Lifestyle Factors', answers: getLifestyleFactors() },
              { title: 'General', answers: getGeneral() },
              { title: 'Health Conditions', answers: getHealthConditions() },
            ]}
          />
        </Grid>
        <Grid item style={{ marginTop: 50 }}>
          <AnswersWithCheckMark
            data={[
              {
                title: isDan(profile) ? 'Performance' : 'Nutrition Goals',
                answers: getPerformance(),
              },
              {
                title: isDan(profile)
                  ? 'Exercise Preferences'
                  : 'Nutrition Preferences',
                answers: getPreferences(),
              },
              {
                title: isDan(profile) ? 'Type of Exercise' : 'Exercise Goals',
                answers: getTypeOfExercises(),
              },
            ]}
          />
        </Grid>
      </Grid>

      <ContinueButtonSection onContinue={() => onContinue && onContinue()} />
    </div>
  );
}
