import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

import { Grid, Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export default function RecipeSummary(props) {
  const isMobile = useMediaQuery('(max-width:900px)');

  const { isHorizontal, largeImage, parentComponent } = props;

  const handleRecipeClick = () => {
    props.onClick && props.onClick();
  };

  const getNormalizedName = () => {
    let name = props.name;

    if (name) {
      return name.normalize();
    }
    return '';
  };

  const getImageSize = () => {
    if (isHorizontal) {
      return largeImage ? 247 : 130;
    } else {
      return largeImage ? (!isMobile ? 223 : 140) : !isMobile ? 150 : 110;
    }
  };

  return (
    <div
      style={{
        borderRadius: '10px',
        boxShadow: isHorizontal ? '0 1px 1px 0 rgba(0, 0, 0, 0.16)' : '',
      }}
    >
      <Grid
        container
        direction="row"
        style={{
          height: isHorizontal
            ? getImageSize()
            : !isMobile
            ? parentComponent === 'home'
              ? '320px'
              : '245px'
            : '190px',
          width: isHorizontal ? 'auto' : getImageSize(),
          cursor: 'pointer',
        }}
        onClick={handleRecipeClick}
      >
        <Grid
          item
          xs={isHorizontal ? 6 : 12}
          style={{
            height: getImageSize(),
            backgroundImage: `url(${props.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px 0 10px  0',
          }}
        ></Grid>
        <Grid
          item
          xs={isHorizontal ? 6 : 12}
          style={{
            height: isHorizontal
              ? '100%'
              : `calc(100% - ${getImageSize()}px + ${
                  !isHorizontal && largeImage ? 30 : 0
                }px)`,
            padding: isHorizontal
              ? '30px 20px 10px 20px'
              : !isMobile
              ? '15px 0'
              : '9px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            style={{
              fontFamily: 'MuseoSans-700',
              fontSize:
                !isMobile && largeImage
                  ? '24px'
                  : !isMobile && !isHorizontal
                  ? '16px'
                  : '14px',
              lineHeight:
                !isMobile && largeImage
                  ? '26px'
                  : !isMobile && !isHorizontal
                  ? '16px'
                  : '16px',
              cursor: 'pointer',
              textAlign: 'left',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: isHorizontal ? 6 : 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {getNormalizedName()}
          </Typography>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {props.numberOfServings && (
              <Typography
                variant="caption"
                style={{
                  fontFamily: 'MuseoSans-700',
                  fontSize:
                    !isMobile && isHorizontal
                      ? '14px'
                      : !isMobile && !isHorizontal
                      ? '12px'
                      : '10px',
                  lineHeight:
                    !isMobile && isHorizontal
                      ? '14px'
                      : !isMobile && !isHorizontal
                      ? '12px'
                      : '10px',
                  right: '95px',
                  bottom: '10px',
                  color: '#636771',
                }}
              >
                {props.numberOfServings}{' '}
                {props.numberOfServings > 1 ? 'serving' : 'servings'}
              </Typography>
            )}
            {isHorizontal && (
              <CheckIcon
                style={{
                  color: '#BFD671',
                  right: '20px',
                  bottom: '8px',
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

RecipeSummary.propTypes = {
  /**
   * URL of the recipe image.
   */
  imageUrl: PropTypes.string.isRequired,
  /**
   * Name of the recipe
   */
  name: PropTypes.string.isRequired,
  /**
   * Inidicates whether summary is horizontal variant.
   */
  isHorizontal: PropTypes.bool.isRequired,
  /**
   * Unique identifier of the recipe.
   */
  uid: PropTypes.string.isRequired,
};
