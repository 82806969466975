import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export const LNTabs = styled(Tabs)({
  borderBottom: '1px solid #ececec',
  '& .MuiTabs-indicator': {
    backgroundColor: '#31B2CF',
  },
});

export const LNTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: 20,
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  /*fontWeight: theme.typography.fontWeightRegular,*/
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#31B2CF',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#31B2CF',
    /*fontWeight: theme.typography.fontWeightMedium,*/
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#31B2CF',
  },
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}