import { Grid, useMediaQuery } from '@mui/material';
import { FlippableProgram } from './FlippableProgram';

export function HeaderSquare(props) {
  const { option } = props;
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <div
      style={{
        height: isMobile ? 527 : 450,
        width: isMobile ? 346 : 600,
        borderRadius: 20,
        boxShadow: '1px 2px #ececec',
        backgroundColor: 'white',
        padding: isMobile ? '50px 30px' : 50,
      }}
    >
      <Grid container direction="column">
        <Grid item style={{ fontSize: 25, fontFamily: 'Museo700' }}>
          {'Program Overview'}
        </Grid>
        <Grid item style={{ fontSize: 20 }}>
          <FlippableProgram option={option} />
        </Grid>
      </Grid>
    </div>
  );
}
