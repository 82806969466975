import { Grid, useMediaQuery } from '@mui/material';
import RecipeSummary from 'components/RecipeSummary';

export function ProgramNutrition(props) {
  const isMobile = useMediaQuery('(max-width:900px)');

  const { recipes } = props;

  const handleRecipeClick = (id) => {
    props.onRecipeClick && props.onRecipeClick(id);
  };
  return (
    <div>
      <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
        <Grid item xs={6}>
          <RecipeSummary
            isHorizontal={true}
            largeImage={true}
            name={recipes[0].recipe.name}
            imageUrl={recipes[0].recipe.mainImage}
            numberOfServings={recipes[0].recipe.numberOfServings}
            onClick={() => {
              handleRecipeClick(0);
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {recipes.map((recipe, ix) => {
            if (ix === 0) {
              return <></>;
            }
            return (
              <div style={{ marginLeft: 5 }}>
                <RecipeSummary
                  isHorizontal={false}
                  largeImage={false}
                  name={recipe.recipe.name}
                  imageUrl={recipe.recipe.mainImage}
                  numberOfServings={recipe.recipe.numberOfServings}
                  onClick={() => {
                    handleRecipeClick(ix);
                  }}
                />
              </div>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
}
