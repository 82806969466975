export const recipes = [
  {
    id: 'TWVhbDoxMzM5MzA0OA==',
    calories: 753.437626355125,
    meal: 'breakfast',
    numOfServings: 1,
    recipe: {
      id: 'UmVjaXBlOjA1MDI3ZjA2LTg1NTgtNDBkMi1iMWQ4LTMyYzMyMjA3OTdiYw==',
      databaseId: '05027f06-8558-40d2-b1d8-32c3220797bc',
      name: 'Healthier French Toast',
      source: null,
      mainImage:
        'https://storage.googleapis.com/sg-uploads/22356165fcf945ea905264d94251aee5.jpg',
      numberOfServings: 1,
      ingredientLines: [
        '1 egg',
        '4 oz whole milk',
        '½ tsp ground cinnamon',
        '1 tbsp butter',
        '4 oz rye bread (2 slices)',
        '4 oz fresh blueberries',
        '4 oz fresh raspberries',
        '1 oz maple syrup, to serve',
      ],
      ingredients: [
        {
          name: 'Egg',
          cpc: '',
        },
        {
          name: 'Milk',
          cpc: 'Foods with high Cholesterol or saturated fat',
        },
        {
          name: 'Cinnamon',
          cpc: '',
        },
        {
          name: 'Butter',
          cpc: 'Foods with high Cholesterol or saturated fat',
        },
        {
          name: 'Rye bread',
          cpc: '',
        },
        {
          name: 'Blueberries',
          cpc: 'Vitamin K - Elevated',
        },
        {
          name: 'Raspberries',
          cpc: 'Vitamin C - Elevated',
        },
        {
          name: 'Maple syrup',
          cpc: '',
        },
      ],
      instructions: [
        'Whisk the eggs, milk and cinnamon together in a bowl until well-combined. Heat a non-stick fry pan over medium heat, melt the butter.',
        'Dip the bread in the egg mixture, evenly coating both sides, shake off any excess mixture. Cook for 2-3 minutes on each side until lightly brown.',
        'Serve French toast warm, with berries and drizzle of maple syrup.',
      ],
      totalTime: '15 minutes',
      totalTimeInSeconds: 900,
      caloriesPerServing: {
        protein: 87.5,
        carbs: 442.43,
        fat: 223.51,
      },
      nutrientsPerServing: {
        vitaminA: 235.07,
        vitaminB1: null,
        vitaminB2: null,
        vitaminB3: null,
        vitaminB5: 2.23,
        vitaminB6: 0.35,
        vitaminB7: 0,
        vitaminB9: null,
        vitaminB12: 0.98,
        vitaminC: 41.21,
        vitaminD: 2.47,
        vitaminE: 2.97,
        vitaminK: 33.98,
        calcium: 319.42,
        choline: 203.69,
        copper: 0,
        iodine: null,
        iron: 5.36,
        magnesium: 101.47,
        manganese: 3.14,
        molybdenum: null,
        phosphorus: 387.3,
        potassium: 734.58,
        selenium: 55.28,
        sodium: 900.58,
        zinc: 3.47,
        protein: 21.87,
        carbs: 110.61,
        fat: 24.83,
        omega3: 0.1,
      },
      adherenceDetails: {
        score: 38.94,
        color: 'RED',
        icon: null,
        title: 'Uh-oh, you may be missing an opportunity to eat right!',
        reason: null,
        isRecommended: true,
      },
      isUserFavorite: false,
      inUserShoppingList: false,
      daily_need: {
        vitaminA: 9,
        vitaminB1: null,
        vitaminB2: null,
        vitaminB3: null,
        vitaminB5: 37,
        vitaminB6: 18,
        vitaminB7: 0,
        vitaminB9: null,
        vitaminB12: 38,
        vitaminC: 48,
        vitaminD: 16,
        vitaminE: 20,
        vitaminK: 38,
        calcium: 32,
        choline: 45,
        copper: 0,
        iodine: null,
        iron: 20,
        magnesium: 29,
        manganese: 157,
        molybdenum: null,
        phosphorus: 55,
        potassium: 25,
        selenium: 92,
        sodium: 60,
        zinc: 32,
        omega3: 7,
      },
      reason: [],
      isSaved: false,
      isLogged: false,
    },
  },
  {
    id: 'TWVhbDoxMzM5MzA0OQ==',
    calories: 666.042441015625,
    meal: 'lunch',
    numOfServings: 1,
    recipe: {
      id: 'UmVjaXBlOmU3YWQyNDI5LTMzMDUtNDFhNy1hMGNlLWEzNmY5ZDQ1NjYzMw==',
      databaseId: 'e7ad2429-3305-41a7-a0ce-a36f9d456633',
      name: 'Chicken Stir Fry With Rice Noodles',
      source: null,
      mainImage:
        'https://storage.googleapis.com/sg-uploads/dcd81cf2a5f94fa984319c787f7a35e3.jpg',
      numberOfServings: 2,
      ingredientLines: [
        '1/4 lb rice noodles',
        '2 tbsp olive oil',
        '3⁄4 lb skinless boneless chicken breasts, sliced',
        'Salt, to taste',
        '1 red bell pepper, sliced',
        '1/2 cup chopped onion',
        '1 tbsp fresh ginger, peeled and grated',
        '½ cup chicken broth',
        '2 tbsp soy sauce',
        '2 tbsp ketchup',
        'Pepper, to taste',
      ],
      ingredients: [
        {
          name: 'Rice noodles',
          cpc: '',
        },
        {
          name: 'Olive oil',
          cpc: 'Vitamin E - Elevated',
        },
        {
          name: 'Chicken breasts',
          cpc: 'Choline - Elevated',
        },
        {
          name: 'Salt',
          cpc: '',
        },
        {
          name: 'Red bell pepper',
          cpc: 'Vitamin A - Elevated',
        },
        {
          name: 'Onion',
          cpc: '',
        },
        {
          name: 'Ginger',
          cpc: '',
        },
        {
          name: 'Chicken broth',
          cpc: '',
        },
        {
          name: 'Soy sauce',
          cpc: 'Foods with high Cholesterol or saturated fat',
        },
        {
          name: 'Ketchup',
          cpc: 'Foods with high Cholesterol or saturated fat',
        },
        {
          name: 'Peppercorns',
          cpc: '',
        },
      ],
      instructions: [
        'Bring medium size pan of salted water to a boil. Add noodles and cook them on medium heat for about 5 minutes. Drain and stir in 1 tbsp of olive oil.',
        'In a large skillet, heat 1 tbsp of olive oil over high heat.',
        'Season chicken lightly with salt and pepper then place it into the hot skillet and stir-fry about 5 minutes or until just cooked through. Remove from the skillet and set aside.',
        'Add onions and bell peppers and saute 1 minute then add ginger to the skillet and stir-fry for another 2 minutes.',
        'In a mixing bowl combine chicken stock, soy sauce, ketchup. Add chicken, noodles, and broth mixture to the skillet and stir-fry for 5 minutes or until chicken is hot and fully cooked through.',
        'Right before serving season to taste with salt and pepper as desired.',
      ],
      totalTime: '30 minutes',
      totalTimeInSeconds: 1800,
      caloriesPerServing: {
        protein: 218.66,
        carbs: 250.8,
        fat: 196.58,
      },
      nutrientsPerServing: {
        vitaminA: 27.16,
        vitaminB1: null,
        vitaminB2: null,
        vitaminB3: null,
        vitaminB5: 0.15,
        vitaminB6: 0.19,
        vitaminB7: 0,
        vitaminB9: null,
        vitaminB12: 0.51,
        vitaminC: 4.62,
        vitaminD: 0.05,
        vitaminE: 2.18,
        vitaminK: 9.47,
        calcium: 56.02,
        choline: 51.2,
        copper: 0,
        iodine: null,
        iron: 2.35,
        magnesium: 29.06,
        manganese: 0.44,
        molybdenum: null,
        phosphorus: 201.18,
        potassium: 204.81,
        selenium: 18.36,
        sodium: 3165.12,
        zinc: 1.86,
        protein: 54.67,
        carbs: 62.7,
        fat: 21.84,
        omega3: 0.12,
      },
      adherenceDetails: {
        score: 29.89,
        color: 'RED',
        icon: null,
        title: 'Uh-oh, you may be missing an opportunity to eat right!',
        reason: null,
        isRecommended: true,
      },
      isUserFavorite: false,
      inUserShoppingList: false,
      daily_need: {
        vitaminA: 1,
        vitaminB1: null,
        vitaminB2: null,
        vitaminB3: null,
        vitaminB5: 2,
        vitaminB6: 10,
        vitaminB7: 0,
        vitaminB9: null,
        vitaminB12: 20,
        vitaminC: 5,
        vitaminD: 0,
        vitaminE: 15,
        vitaminK: 11,
        calcium: 6,
        choline: 11,
        copper: 0,
        iodine: null,
        iron: 9,
        magnesium: 8,
        manganese: 22,
        molybdenum: null,
        phosphorus: 29,
        potassium: 7,
        selenium: 31,
        sodium: 211,
        zinc: 17,
        omega3: 9,
      },
      reason: [],
      isSaved: false,
      isLogged: false,
    },
  },
  {
    id: 'TWVhbDoxMzM5MzA1MA==',
    calories: 193.254914401969,
    meal: 'snack',
    numOfServings: 1,
    recipe: {
      id: 'UmVjaXBlOjNiNzQ0NmE1LTgxNjEtNGEyNC1hYThiLWVhNzBjZTk1OTE3YQ==',
      databaseId: '3b7446a5-8161-4a24-aa8b-ea70ce95917a',
      name: 'Hokkaido Soup',
      source: null,
      mainImage:
        'https://storage.googleapis.com/sg-uploads/ebd75a5b4749452698697dbfc7a8155d.jpg',
      numberOfServings: 4,
      ingredientLines: [
        '1 tbsp olive oil',
        '1 medium onion, chopped',
        '2 garlic cloves, pressed',
        '2 lb hokkaido pumpkin, deseeded, peeled and cubed',
        '3 medium potatoes, peeled and diced',
        '5 cups vegetable stock',
        '2 oz feta cheese, crumbled',
        'Salt, to taste',
        'Fresh cracked black pepper, to taste',
      ],
      ingredients: [
        {
          name: 'Olive oil',
          cpc: 'Vitamin E - Elevated',
        },
        {
          name: 'Onion',
          cpc: '',
        },
        {
          name: 'Garlic cloves',
          cpc: '',
        },
        {
          name: 'Pumpkin',
          cpc: 'Vitamin A - Elevated',
        },
        {
          name: 'Potatoes',
          cpc: 'Vitamin B3 - Elevated',
        },
        {
          name: 'Vegetable stock',
          cpc: '',
        },
        {
          name: 'Feta cheese',
          cpc: 'Foods with high Cholesterol or saturated fat',
        },
        {
          name: 'Salt',
          cpc: '',
        },
        {
          name: 'Black pepper',
          cpc: '',
        },
      ],
      instructions: [
        'Heat a saucepan on medium heat until the olive oil is shimmering. Add the chopped onion and garlic, saute for 3-4 minutes.',
        'Once the onion is tender but still white, pour the veggie broth in. Add pumpkin and potatoes. Cook for 20-30 minutes.',
        'Use a stick blender to puree the soup. Season to taste using salt and pepper, serve hot, topped with crumbled feta cheese.',
      ],
      totalTime: '30 minutes',
      totalTimeInSeconds: 1800,
      caloriesPerServing: {
        protein: 22.1,
        carbs: 110.61,
        fat: 60.54,
      },
      nutrientsPerServing: {
        vitaminA: 966.31,
        vitaminB1: null,
        vitaminB2: null,
        vitaminB3: null,
        vitaminB5: 0.82,
        vitaminB6: 0.25,
        vitaminB7: 0,
        vitaminB9: null,
        vitaminB12: 0,
        vitaminC: 26.62,
        vitaminD: 0,
        vitaminE: 2.9,
        vitaminK: 5.63,
        calcium: 118.92,
        choline: 21.11,
        copper: 0,
        iodine: null,
        iron: 2.9,
        magnesium: 38.77,
        manganese: 0.59,
        molybdenum: null,
        phosphorus: 123.13,
        potassium: 955.01,
        selenium: 1,
        sodium: 1455.08,
        zinc: 0.9,
        protein: 5.53,
        carbs: 27.65,
        fat: 6.73,
        omega3: 0,
      },
      adherenceDetails: {
        score: 40.38,
        color: 'RED',
        icon: null,
        title: 'Uh-oh, you may be missing an opportunity to eat right!',
        reason: null,
        isRecommended: true,
      },
      isUserFavorite: false,
      inUserShoppingList: false,
      daily_need: {
        vitaminA: 38,
        vitaminB1: null,
        vitaminB2: null,
        vitaminB3: null,
        vitaminB5: 14,
        vitaminB6: 13,
        vitaminB7: 0,
        vitaminB9: null,
        vitaminB12: 0,
        vitaminC: 31,
        vitaminD: 0,
        vitaminE: 19,
        vitaminK: 6,
        calcium: 12,
        choline: 5,
        copper: 0,
        iodine: null,
        iron: 11,
        magnesium: 11,
        manganese: 30,
        molybdenum: null,
        phosphorus: 18,
        potassium: 33,
        selenium: 2,
        sodium: 97,
        zinc: 8,
        omega3: 0,
      },
      reason: [],
      isSaved: false,
      isLogged: false,
    },
  },
  {
    id: 'TWVhbDoxMzM5MzA1MQ==',
    calories: 518.468422277708,
    meal: 'dinner',
    numOfServings: 1,
    recipe: {
      id: 'UmVjaXBlOmI4OTZmZTBkLWZjMjgtNDI4Yy05NTc3LWY2ODI0ODJhODlhMA==',
      databaseId: 'b896fe0d-fc28-428c-9577-f682482a89a0',
      name: 'Eggs Benedict Salad',
      source: null,
      mainImage:
        'https://storage.googleapis.com/sg-uploads/27d93a59459c45688e987f9be34a6c40.jpg',
      numberOfServings: 1,
      ingredientLines: [
        '1 large egg',
        '1 slice of turkey bacon chopped into pieces',
        '4 precooked stalks asparagus',
        '4 cups spinach & arugula salad mix or greens of choice',
        '1/8 cup sliced red onion',
        '3 teaspoon extra virgin olive oil',
        '2 teaspoon white balsamic vinegar',
        '2 teaspoon Dijon mustard',
        'Salt',
        '1/8 teaspoon black pepper',
        '1 oz whole grain bread',
      ],
      ingredients: [
        {
          name: 'Egg',
          cpc: '',
        },
        {
          name: 'Turkey bacon',
          cpc: 'Foods with high Cholesterol or saturated fat',
        },
        {
          name: 'Asparagus',
          cpc: 'Vitamin B9 - Elevated',
        },
        {
          name: 'Spinach',
          cpc: 'Vitamin D - Elevated',
        },
        {
          name: 'Red onion',
          cpc: '',
        },
        {
          name: 'Olive oil',
          cpc: 'Vitamin E - Elevated',
        },
        {
          name: 'White balsamic vinegar',
          cpc: '',
        },
        {
          name: 'Dijon style mustard',
          cpc: '',
        },
        {
          name: 'Salt',
          cpc: '',
        },
        {
          name: 'Black pepper',
          cpc: '',
        },
        {
          name: 'Bread',
          cpc: '',
        },
      ],
      instructions: [
        'Whisk together in a small bowl 2 teaspoon extra virgin olive oil, white balsamic vinegar, Dijon mustard & salt/pepper',
        'To a nonstick pan add 1 teaspoon extra virgin olive oil & heat over medium-high heat.',
        'Saute bacon 3-4 minutes.',
        'Add asparagus & red onion cooking another 2-3 minutes until onion is tender.',
        'In a bowl, add greens & top with dressing combining well.',
        'Top with cooked asparagus, onion & bacon.',
        'Add eggs to pan & cook as desired.',
        'To the salad add your cooked eggs and serve with bread.',
        'Feast!',
      ],
      totalTime: '15 minutes',
      totalTimeInSeconds: 900,
      caloriesPerServing: {
        protein: 114.54,
        carbs: 199.66,
        fat: 204.27,
      },
      nutrientsPerServing: {
        vitaminA: 285.26,
        vitaminB1: null,
        vitaminB2: null,
        vitaminB3: null,
        vitaminB5: 2.39,
        vitaminB6: 0.67,
        vitaminB7: 0,
        vitaminB9: null,
        vitaminB12: 0.6,
        vitaminC: 61.75,
        vitaminD: 1.1,
        vitaminE: 6.7,
        vitaminK: 591.79,
        calcium: 368.52,
        choline: 246.03,
        copper: 0,
        iodine: null,
        iron: 16.31,
        magnesium: 105.87,
        manganese: 1.47,
        molybdenum: null,
        phosphorus: 476.26,
        potassium: 2252.32,
        selenium: 39.33,
        sodium: 3068.54,
        zinc: 4.41,
        protein: 28.63,
        carbs: 49.92,
        fat: 22.7,
        omega3: 0.09,
      },
      adherenceDetails: {
        score: 42.01,
        color: 'RED',
        icon: null,
        title: 'Uh-oh, you may be missing an opportunity to eat right!',
        reason: null,
        isRecommended: true,
      },
      isUserFavorite: false,
      inUserShoppingList: false,
      daily_need: {
        vitaminA: 11,
        vitaminB1: null,
        vitaminB2: null,
        vitaminB3: null,
        vitaminB5: 40,
        vitaminB6: 35,
        vitaminB7: 0,
        vitaminB9: null,
        vitaminB12: 23,
        vitaminC: 73,
        vitaminD: 7,
        vitaminE: 45,
        vitaminK: 658,
        calcium: 37,
        choline: 55,
        copper: 0,
        iodine: null,
        iron: 60,
        magnesium: 30,
        manganese: 74,
        molybdenum: null,
        phosphorus: 68,
        potassium: 78,
        selenium: 66,
        sodium: 205,
        zinc: 40,
        omega3: 6,
      },
      reason: [],
      isSaved: false,
      isLogged: false,
    },
  },
];
