import {
  DanExerciseVideo1,
  DanExerciseVideo2,
  DanExerciseVideo3,
  DanExerciseVideo4,
  DanExerciseVideo5,
  DanExerciseVideo6,
  SophieExerciseVideo1,
  SophieExerciseVideo2,
  SophieExerciseVideo3,
  SophiePregnancyVideo1,
  SophiePregnancyVideo2,
  SophiePregnancyVideo3,
  SophieNutritionVideo1,
  SophieNutritionVideo2,
  SophieNutritionVideo3,
} from 'assets/images/imagesList';

export const danExerciseVideos1 = [
  {
    title: '15 Min HIIT CARDIO Workout',
    image: DanExerciseVideo1,
    recommendations: [
      'Endurance (DNA)',
      'Type-II Diabetes (Health)',
      'Cardio Impact on Cholesterol (DNA)',
      'Heart Rate Variability (Wearable)',
    ],
    link: 'https://www.youtube.com/watch?v=SExZg3yRpMA',
  },
  {
    title: '15 Min Power Walk',
    image: DanExerciseVideo2,
    recommendations: [
      'Cardio impact on VO2 max (DNA)',
      'Joint Injury Potential (DNA)',
      'ACL Rupture (Past Conditions)',
      'Heart Rate Variability (Wearable)',
    ],
    link: 'https://www.youtube.com/watch?v=6C92d9e-11o&ab_channel=WalkatHome',
  },
  {
    title: '30 Minute Cardio Resistance Training',
    image: DanExerciseVideo3,
    recommendations: [
      'Muscle Response to Strength Training',
      'Joint Injury Potential (DNA)',
      'ACL Rupture (Past Conditions)',
      'Cardio Impact on Cholesterol',
      'Heart Rate Variability (Wearable)',
    ],
    link: 'https://www.youtube.com/watch?v=SExZg3yRpMA',
  },
];

export const danExerciseVideos2 = [
  {
    title: 'Good Morning Full Body Stretch',
    image: DanExerciseVideo4,
    recommendations: [
      'Endurance (DNA)',
      'Type-II Diabetes (Health)',
      'Heart Rate Variability (Wearable)',
    ],
    link: 'https://www.youtube.com/watch?v=JhSZw8fiTLc&ab_channel=AllblancTV',
  },
  {
    title: '30-Minute HIIT Cardio Workout With Warm Up',
    image: DanExerciseVideo5,
    recommendations: [
      'Cardio impact on VO2 max (DNA)',
      'Joint Injury Potential (DNA)',
      'ACL Rupture (Past Conditions)',
      'Heart Rate Variability (Wearable)',
    ],
    link: 'https://www.youtube.com/watch?v=ml6cT4AZdqI&ab_channel=SELF',
  },
  {
    title: 'Classic 2 Mile Workout By Walk At Home',
    image: DanExerciseVideo6,
    recommendations: [
      'Muscle Response to Strength Training',
      'Joint Injury Potential (DNA)',
      'ACL Rupture (Past Conditions)',
      'Cardio Impact on Cholesterol',
      'Heart Rate Variability (Wearable)',
    ],
    link: 'https://www.youtube.com/watch?v=UNA_1DXsLmg&ab_channel=WalkatHome',
  },
];

export const getDanExerciseSchedule = (option) => {
  if (option === 1) {
    return [
      {
        name: 'MONDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Casual Walk (15 minutes)',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Cardio-Resistance Training I - 30 Minutes ',
          },
        ],
      },
      {
        name: 'TUESDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Casual Walk (15 minutes)',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Power Walk - 30 Minutes ',
          },
        ],
      },
      {
        name: 'WEDNESDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Casual Walk (15 minutes)',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Cardio-Resistance Training I - 30 Minutes ',
          },
        ],
      },
      {
        name: 'THURSDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Casual Walk (15 minutes)',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Power Walk - 30 Minutes ',
          },
        ],
      },
      {
        name: 'FRIDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Casual Walk (15 minutes)',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Cardio-Resistance Training I - 30 Minutes ',
          },
        ],
      },
    ];
  } else {
    return [
      {
        name: 'MONDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Power Walk - 5 Min',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Intro into Fitness - 5 Min',
          },
        ],
      },
      {
        name: 'TUESDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Casual Walk (15 minutes)',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Power Walk - 5 Minutes ',
          },
        ],
      },
      {
        name: 'WEDNESDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Power Walk - 5 Min',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Intro into Fitness - 5 Min ',
          },
        ],
      },
      {
        name: 'THURSDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Casual Walk (25 minutes)',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Power Walk - 5 Minutes ',
          },
        ],
      },
      {
        name: 'FRIDAY',
        items: [
          {
            value: 'MORNING',
            title: true,
          },
          {
            value: 'Stretch Exercises (10 minutes)',
          },
          {
            value: 'Casual Walk (25 minutes)',
          },
          {
            value: 'AFTERNOON/AFTER WORK',
            title: true,
          },
          {
            value: 'Intro into Fitness - 5 Minutes',
          },
        ],
      },
    ];
  }
};

export const sophieExerciseVideos = [
  {
    title: 'Pregnancy Morning Stretch',
    image: SophieExerciseVideo1,
    recommendations: ['Pregnancy Nutrition Tips - Second Trimester'],
    link: 'https://www.youtube.com/watch?v=Sja4az8lnvc&ab_channel=PregnancyandPostpartumTV',
  },
  {
    title: 'Kegel Exercise Starting Week 20',
    image: SophieExerciseVideo2,
    recommendations: ['Pregnancy Nutrition Tips: - Joint Injury'],
    link: 'https://www.youtube.com/watch?v=BwYenLQkeWo&ab_channel=PregnancyandPostpartumTV',
  },
  {
    title: 'Pregnancy Yoga For Second Trimester',
    image: SophieExerciseVideo3,
    recommendations: ['Pregnancy Nutrition Tips: - Joint Injury'],
    link: 'https://www.youtube.com/watch?v=D75ClMK09TA&ab_channel=PregnancyandPostpartumTV',
  },
];

export const sophieNutritionVideos = [
  {
    title: 'Pregnancy Diet Tips',
    image: SophieNutritionVideo1,
    recommendations: ['Pregnancy Nutrition Tips - Second Trimester'],
    link: 'https://www.youtube.com/watch?v=BLBbEPGJEAY&ab_channel=WhitneyE.RD',
  },
  {
    title: 'Veggie Lentil Stew',
    image: SophieNutritionVideo2,
    recommendations: ['Pregnancy Nutrition Tips - Second Trimester'],
    link: 'https://www.youtube.com/watch?v=iTSjQYml0qQ&feature=youtu.be&ab_channel=HealthNutNutrition',
  },
  {
    title: '5 Min Breakfasts',
    image: SophieNutritionVideo3,
    recommendations: ['Pregnancy Nutrition Tips - Second Trimester'],

    link: 'https://www.youtube.com/watch?v=FJhGIPGDhOc&ab_channel=HealthNutNutrition',
  },
];

export const sophiePregnancyVideos = [
  {
    title: 'Dealing with Stress in Pregnancy',
    image: SophiePregnancyVideo1,
    recommendations: ['Stress elevated', 'Second Trimester'],
    link: 'https://www.youtube.com/watch?v=fm4ULsZdDFg&feature=emb_logo',
  },
  {
    title: '10 Ways To Enjoy Pregnancy & Keep Stress Away',
    image: SophiePregnancyVideo3,
    recommendations: ['Stress elevated', 'Second Trimester'],
    link: 'https://www.youtube.com/watch?v=GqA6TlTFmU4&ab_channel=FirstCryParenting',
  },
  {
    title: 'Best Position For Sleeping During Pregnancy',
    image: SophiePregnancyVideo2,
    recommendations: ['Stress elevated', 'Second Trimester'],
    link: 'https://www.youtube.com/watch?v=RvCBdsbQqmY&ab_channel=MattressClarity',
  },
];
