import React from 'react';

import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

/**
 * Progress indicator component based on https://material-ui.com/components/progress/#linear
 * @param {*} props All possible props can be found in docs https://material-ui.com/api/linear-progress/
 */
export default function CustomLinearProgress(props) {
  const {
    value,
    progressColor,
    backgroundColor,
    height,
    borderRadius,
    ...rest
  } = props;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: height,
    borderRadius: borderRadius,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: progressColor,
    },
  }));

  return (
    <div style={{ flexGrow: 1, width: '100%' }}>
      <BorderLinearProgress
        variant="determinate"
        value={value > 100 ? 100 : value}
        {...rest}
      />
    </div>
  );
}
