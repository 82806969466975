import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListContent from 'components/ListContent';
import Instructions from 'components/RecipeInstructions';

import NutrientInfo from './NutrientInfo';

export default function RecipeDetails(props) {
  const isMobile = useMediaQuery('(max-width:900px)');
  const [openedPanels, setOpenedPanels] = useState(
    window.innerWidth > 800 ? ['ingredients', 'instructions'] : []
  );

  const { recipe, meal } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getInstructions = () => {
    if (recipe && recipe.instructions && recipe.instructions.length > 0) {
      return recipe.instructions;
    } else if (
      recipe &&
      recipe.source &&
      recipe.source.recipeUrl &&
      recipe.source.displayName &&
      recipe.source.recipeUrl.length > 0 &&
      recipe.source.displayName.length > 0
    ) {
      return recipe.source;
    } else {
      return null;
    }
  };

  const calculateCalories = () => {
    let calories = 0;
    try {
      if (recipe.caloriesPerServing.protein) {
        calories += recipe.caloriesPerServing.protein;
      }
      if (recipe.caloriesPerServing.protein) {
        calories += recipe.caloriesPerServing.carbs;
      }
      if (recipe.caloriesPerServing.fat) {
        calories += recipe.caloriesPerServing.fat;
      }
    } catch {}
    return calories;
  };

  const handleOpenedPanels = (panel) => {
    let panels = [...openedPanels];

    const index = panels.indexOf(panel);
    if (index > -1) {
      panels.splice(index, 1);
    } else {
      panels.push(panel);
    }
    setOpenedPanels(panels);
  };

  const renderInfoText = (number, text) => {
    return (
      <>
        <Typography
          variant="body1"
          style={{
            fontFamily: 'Museo700',
            fontSize: !isMobile ? '36px' : '30px',
            lineHeight: !isMobile ? '36px' : '30px',
          }}
        >
          {number}
        </Typography>
        <Typography
          variant="caption"
          style={{
            fontFamily: 'Museo700',
            fontSize: !isMobile ? '8px' : '7px',
            lineHeight: !isMobile ? '10px' : '9px',
          }}
        >
          {text}
        </Typography>
      </>
    );
  };

  const renderServingsInfoText = (title, caption, number) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: '10px' }}
      >
        <Grid item>
          <Typography
            variant="h5"
            style={{
              fontFamily: 'Museo700',
              fontSize: !isMobile ? '20px' : '16px',
              lineHeight: !isMobile ? '30px' : '20px',
              color: '#121114',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: 'Museo300',
              fontSize: !isMobile ? '12px' : '10px',
              lineHeight: !isMobile ? '20px' : '15px',
              color: '#342f38',
            }}
          >
            {caption}
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <Typography
            variant="h5"
            style={{
              fontFamily: 'Museo500',
              fontSize: !isMobile ? '30px' : '30px',
              lineHeight: !isMobile ? '40px' : '40px',
              color: '#121114',
            }}
          >
            {number}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: 'Museo300',
              fontSize: !isMobile ? '7px' : '7px',
              lineHeight: !isMobile ? '9px' : '9px',
              color: '#342f38',
            }}
          >
            {number > 1 ? 'servings' : 'serving'}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderAccordion = (id, title, children) => {
    return (
      <Accordion
        square
        expanded={openedPanels.includes(id)}
        onChange={() => handleOpenedPanels(id)}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            variant="body1"
            style={{
              fontSize: !isMobile ? '20px' : '14px',
              lineHeight: !isMobile ? '24px' : '17px',
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  };

  const renderReasons = () => {
    return (
      <>
        <Typography
          variant="body1"
          textAlign="left"
          style={{
            marginTop: 20,
            fontSize: !isMobile ? '20px' : '14px',
            lineHeight: !isMobile ? '24px' : '17px',
            textTransform: 'uppercase',
          }}
        >
          Recommended because
        </Typography>
        <Box style={{ marginTop: '30px' }}>
          <Typography style={{ fontSize: 15, fontHeight: 18, fontWeight: 700 }}>
            #Potassium Need #Vitamin C Need
          </Typography>
        </Box>
      </>
    );
  };

  const nutrientsPerServingKeys = Object.keys(recipe.nutrientsPerServing);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
      }}
    >
      {/* First Section Start */}
      <Grid
        container
        direction="column"
        justifyContent="end"
        alignItems="center"
        style={{
          height: 800,
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.47)) , url(${
            recipe.mainImage ? recipe.mainImage : ''
          })`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          textAlign: 'center',
          color: 'white',
        }}
      >
        <Grid item style={{ width: '100%', marginBottom: 60 }}>
          <Typography variant="h3" style={{ fontSize: 45, fontWeight: 700 }}>
            {recipe.name}
          </Typography>
        </Grid>

        <Grid item style={{ width: !isMobile ? '450px' : '100%' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              paddingLeft: '40px',
              paddingRight: '40px',
              paddingBottom: !isMobile ? '250px' : '60px',
              textAlign: 'center',
              textTransform: 'uppercase',
              color: 'white',
            }}
          >
            {recipe.ingredients && (
              <Grid item>
                {renderInfoText(recipe.ingredients.length, 'ingredients')}
              </Grid>
            )}
            {recipe.totalTime && (
              <Grid item>
                {renderInfoText(
                  recipe.totalTime.substring(
                    0,
                    recipe.totalTime.lastIndexOf(' ')
                  ),
                  'minutes'
                )}
              </Grid>
            )}
            {calculateCalories() > 0 && (
              <Grid item>
                {renderInfoText(
                  Math.round(calculateCalories()),
                  'serving info'
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* First Section End */}

      <Box
        style={{
          maxWidth: '1024px',
          minHeight: !isMobile ? '350px' : '450px',
          margin: 'auto',
          marginTop: !isMobile ? '-240px' : '-50px',
          borderRadius: '50px 50px 0 0',
          backgroundColor: 'white',
          paddingBottom: 100,
        }}
      >
        <Grid
          container
          direction="row"
          style={{
            padding: '40px 20px 0 20px',
          }}
          spacing={!isMobile && 2}
        >
          <Grid item xs={!isMobile ? 6 : 12}>
            {renderServingsInfoText(
              'Recipe',
              'The number of servings in this recipe',
              recipe.numberOfServings
            )}
          </Grid>
          <Grid item xs={!isMobile ? 6 : 12}>
            {renderServingsInfoText(
              'Recommended for you',
              'The number of servings in your meal plan',
              props.numberOfServings
            )}
          </Grid>
          <Grid item xs={!isMobile ? 6 : 12}>
            {renderAccordion(
              'ingredients',
              'ingredients',
              <ListContent data={recipe.ingredientLines} />
            )}
            {!isMobile && renderReasons()}
          </Grid>
          {getInstructions() != null && (
            <Grid item xs={!isMobile ? 6 : 12}>
              {renderAccordion(
                'instructions',
                'instructions',
                <Instructions data={getInstructions()} addMargin />
              )}
              {isMobile && renderReasons()}
            </Grid>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          style={{
            marginTop: 40,
            padding: '40px 20px 40px ',
            backgroundColor: '#F0ECDF',
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{ marginBottom: 20, paddingRight: !isMobile && 70 }}
          >
            <Typography
              style={{ fontSize: 16, fontHeight: 19, fontWeight: 700 }}
            >
              Nutritional values
            </Typography>
            <Typography
              style={{
                fontSize: 10,
                fontHeight: 19,
                fontWeight: 300,
                marginBottom: 20,
              }}
            >
              Your daily intake & goal based on your recommended serving size
            </Typography>
            <Typography
              style={{ fontSize: 14, fontHeight: 19, fontWeight: 700 }}
            >
              Macronutrients
            </Typography>
            {nutrientsPerServingKeys.map((key, index) => {
              if (['carbs', 'fat', 'omega3'].includes(key)) {
                let dailyNeed = recipe.daily_need[key];
                let perServing = recipe.nutrientsPerServing[key];

                let covered =
                  perServing && dailyNeed ? (perServing / dailyNeed) * 100 : 0;

                return (
                  <NutrientInfo
                    name={key}
                    covered={covered.toFixed(0)}
                    need={dailyNeed}
                    unit={'kcal/day'}
                  />
                );
              } else {
                return <></>;
              }
            })}
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingRight: !isMobile && 70 }}>
            <Typography
              style={{ fontSize: 14, fontHeight: 19, fontWeight: 700 }}
            >
              Micronutrients
            </Typography>

            {nutrientsPerServingKeys.map((key, index) => {
              if (!['carbs', 'fat', 'omega3'].includes(key)) {
                let dailyNeed = recipe.daily_need[key];
                let perServing = recipe.nutrientsPerServing[key];

                let covered =
                  perServing && dailyNeed ? (perServing / dailyNeed) * 100 : 0;

                return (
                  <NutrientInfo
                    name={key}
                    covered={covered.toFixed(0)}
                    need={dailyNeed}
                    unit={'kcal/day'}
                  />
                );
              } else {
                return <></>;
              }
            })}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
