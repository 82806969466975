import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';

import { SophieProgram, ArrowRight } from 'assets/images/imagesList';

export function FlippableProgram(props) {
  const { option } = props;
  const [isFlipped, setIsFlipped] = useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Grid
      container
      onClick={() => {
        if (option === 1 || option === 2) {
          setIsFlipped(!isFlipped);
        }
      }}
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      style={{
        width: isMobile ? 346 : 540,
        height: isMobile ? 527 : 440,
        perspective: '1000',
        backgroundColor: 'white',
        fontFamily: 'Museo300',
        cursor: 'pointer',
      }}
    >
      <Grid
        item
        style={{
          transform: isFlipped ? 'rotateY(180deg)' : 'none',
          position: 'relative',
          width: '100%',
          transition: 'transform 0.6s',
          transformStyle: 'preserve-3d',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            webkitBackfaceVisibility: 'hidden',
            backfaceVisibility: 'hidden',
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          {(option === 1 || option === 2) && (
            <Grid container direction="column">
              <Grid
                item
                style={{ marginTop: 50, fontSize: 16, fontHeight: 19 }}
              >
                Ramp-Up programs are designed to prepare the body for regular
                exercise readiness. XHealth ramp-up programs start with
                achievable daily goals and increase the intensity and breadth of
                exercises over time based on your progress. The programs can
                last between 6-12 weeks depending on how fast your body regains
                that readiness.
              </Grid>
              <Grid
                item
                style={{ marginTop: 50, fontSize: 16, fontHeight: 19 }}
              >
                The power walk ramp-up program starts with a combination of
                casual and power walk intervals, where the proportion of power
                walking increases over time. The intro to fitness program allows
                other parts of the body to prepare for regular fitness at home.
              </Grid>
            </Grid>
          )}
          {(option === 3 || option === 4 || option === 5) && (
            <Grid container direction="row">
              <Grid
                item
                xs={12}
                style={{ marginTop: 50, fontSize: 16, fontHeight: 19 }}
              >
                Now that you’re at week 20, your baby may start moving around if
                they haven’t already. Keep track of how often they move so that
                you can keep your doctor in-the-know.
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ marginTop: 50, fontSize: 16, fontHeight: 19 }}
              >
                <img
                  src={SophieProgram}
                  alt="sophie_program"
                  style={{ width: 250 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ marginTop: 50, fontSize: 16, fontHeight: 19 }}
              >
                <div>What's Good</div>
                <div>
                  Week 20 is also a good time to start prepping your pelvic
                  muscles.
                </div>
                <div>Prepping pelvic muscles</div>
                <div>
                  Do simple Kegel exercises throughout the day to build pelvic
                  strength.
                </div>
              </Grid>
            </Grid>
          )}
        </div>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            webkitBackfaceVisibility: 'hidden',
            backfaceVisibility: 'hidden',
            backgroundColor: 'white',
            color: 'black',
            transform: 'rotateY(180deg)',
          }}
        >
          {(option === 1 || option === 2) && (
            <Grid
              container
              direction="column"
              style={{ fontSize: 16, fontHeight: 19 }}
            >
              <Grid item style={{ marginTop: 25 }}>
                <Grid container direction="row">
                  <Grid item xs={5} style={{ width: 150 }}>
                    DNA
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container direction="column">
                      <Grid item>Power</Grid>
                      <Grid item style={{ marginTop: 5 }}>
                        Overall Tendon and Ligament Injury Risk
                      </Grid>
                      <Grid item style={{ marginTop: 5 }}>
                        Muscle Tear Risk (DNA)
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: 25 }}>
                <Grid container direction="row">
                  <Grid item xs={5} style={{ width: 150 }}>
                    WEARABLE
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container direction="column">
                      <Grid item>Fitness Level: 1</Grid>
                      <Grid item style={{ marginTop: 5 }}>
                        Activity Level
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: 25 }}>
                <Grid container direction="row">
                  <Grid item xs={5} style={{ width: 150 }}>
                    PROFILE
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container direction="column">
                      <Grid item>BMI</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: 50, fontFamily: 'Museo500' }}>
                SCHEDULE
              </Grid>
              <Grid item style={{ marginTop: 25 }}>
                Weeks 1-2:
              </Grid>
              <Grid item style={{ marginTop: 5 }}>
                3 x (5 minutes Power Walk + 25 minutes casual walk) +
              </Grid>
              <Grid item style={{ marginTop: 5 }}>
                2 x 5 Min Intro to Fitness
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
      <Grid item style={{ width: '100%', textAlign: 'right' }}>
        {isFlipped ? 'Schedule' : 'Overview'}{' '}
        <img
          src={ArrowRight}
          width="17"
          height="15"
          alt="illustration for next"
        />
      </Grid>
    </Grid>
  );
}
