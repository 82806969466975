import { Grid, useMediaQuery } from '@mui/material';
import { ContinueButton } from 'components/ContinueButton';

export function ContinueButtonSection(props) {
  const { onContinue, disabled } = props;
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {!isMobile && (
        <Grid item style={{ marginTop: 73, marginBottom: 73 }}>
          <ContinueButton
            disabled={disabled}
            onClick={() => onContinue && onContinue()}
          />
        </Grid>
      )}
      {isMobile && (
        <Grid
          item
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 9999,
            borderTop: '1px Solid #ececec',
            padding: 10,
            backgroundColor: 'rgba(255, 255, 255)',
          }}
        >
          <ContinueButton
            disabled={disabled}
            onClick={() => onContinue && onContinue()}
          />
        </Grid>
      )}
    </Grid>
  );
}
