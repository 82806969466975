import { Grid, useMediaQuery } from '@mui/material';
import {
  Dan,
  Sophie,
  Woman,
  StartHeaderBackground,
} from 'assets/images/imagesList';
import { Age } from 'components/Age';
import { DiscoverButton } from 'components/DiscoverButton';
import { HeaderLogo } from 'components/HeaderLogo';

export function Start(props) {
  const { onProfileSelected } = props;
  const isMobile = useMediaQuery('(max-width:900px)');

  const getText = () => {
    return (
      <div
        style={{
          // paddingRight: isMobile ? 20 : 250,
          paddingLeft: 30,
          paddingBottom: 30,
        }}
      >
        <Grid container>
          <Grid
            item
            style={{
              fontSize: isMobile ? 30 : 38,
              marginTop: isMobile ? 40 : 100,
              width: '100%',
              fontFamily: 'Museo500',
              textAlign: isMobile ? 'center' : 'left',
            }}
          >
            <span>LifeNome Precision Demo</span>
          </Grid>
          <Grid
            item
            style={{
              fontSize: isMobile ? 16 : 24,
              marginTop: isMobile ? 20 : 30,
              fontFamily: 'Museo300',
            }}
          >
            LifeNome provides your brand with personalized solutions to enhance
            the efficacy of your products or services.
          </Grid>
          {!isMobile && (
            <Grid
              item
              style={{ fontSize: 30, marginTop: 30, fontFamily: 'Museo500' }}
            >
              Choose a profile to start a journey
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          height: isMobile ? 816 : 616,
          background: `url(${StartHeaderBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
          overflow: 'hidden',
          '-webkit-mask-image': `url(${StartHeaderBackground})`,
          maskImage: `url(${StartHeaderBackground})`,
          '-webkit-mask-repeat': 'no-repeat',
          maskRepeat: 'no-repeat',
          '-webkit-mask-size': 'cover',
          maskSize: 'cover',
          '-webkit-mask-position': 'center bottom',
          maskPosition: 'center bottom',
          display: isMobile && 'flex',
          flexDirection: isMobile && 'column',
          justifyContent: isMobile && 'space-between',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent={isMobile ? 'center' : 'flex-end'}
          alignItems="flex-start"
          paddingBottom="20"
        >
          <HeaderLogo />
        </Grid>

        {!isMobile && (
          <Grid
            container
            direction={isMobile ? 'column' : 'row'}
            style={{ maxWidth: 1200, margin: 'auto' }}
          >
            <Grid item sm={12} md={6}>
              <img
                src={Woman}
                width={450}
                alt="illustration of a person"
                style={{
                  marginLeft: !isMobile && 50,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              {getText()}
            </Grid>
          </Grid>
        )}

        {isMobile && <Grid container>{getText()}</Grid>}

        <img
          src={Woman}
          width={370}
          alt="illustration of a person"
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: 200 }}
      >
        <Grid item style={{ marginLeft: 40, marginRight: 40, marginTop: 50 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                src={Sophie}
                width="350px"
                alt="illustration of a woman persona"
              />
            </Grid>
            <Grid
              item
              style={{ fontSize: 38, marginTop: 20, fontFamily: 'Museo700' }}
            >
              Sophie K.
            </Grid>
            <Age value="34" />
            <DiscoverButton
              name="Sophie"
              color="#9497F0"
              onClick={() =>
                onProfileSelected && onProfileSelected('Sophie K.')
              }
            />
          </Grid>
        </Grid>
        <Grid item style={{ marginLeft: 40, marginRight: 40, marginTop: 50 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                src={Dan}
                width="350px"
                alt="illustration of a man persona"
              />
            </Grid>
            <Grid
              item
              style={{ fontSize: 38, marginTop: 20, fontFamily: 'Museo700' }}
            >
              Dan B.
            </Grid>
            <Age value="41" />
            <DiscoverButton
              name="Dan"
              color="#F4AE3E"
              onClick={() => onProfileSelected && onProfileSelected('Dan B.')}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
