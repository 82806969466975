import LinearProgress from './LinearProgress';
import { Grid, Typography } from '@mui/material';

export default function NutrientInfo(props) {
  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="row">
          <Grid item xs={4} style={{ height: '18px' }}>
            <Typography
              variant="body1"
              style={{ fontSize: '12px', textAlign: 'left' }}
            >
              {props.name}
            </Typography>
          </Grid>
          <Grid item xs={8} style={{ height: '18px' }}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    fontWeight: props.textStyle,
                  }}
                >
                  {props.covered}
                  {'%'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: '12px',
                    textAlign: 'end',
                    fontWeight: props.textStyle,
                  }}
                >
                  {props.need} {props.unit}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <LinearProgress
          backgroundColor="#e0e0e0"
          borderRadius={6}
          height={6}
          progressColor="#bfd671"
          value={props.covered}
        />
      </Grid>
    </Grid>
  );
}
