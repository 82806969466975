import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { ContinueButtonSection } from 'components/ContinueButtonSection';
import { Header } from 'components/Header';
import { FlippedCardSmall } from 'components/FlippedCardSmall';
import { InsightTile } from 'components/InsightTile';
import { WearableTile } from 'components/WearableTile';
import { HealthTile } from 'components/HealthTile';
import { HealthTable } from 'components/HealthTable';
import { GoalTable } from 'components/GoalsTable';
import { GoalTargets } from 'components/GoalTargets';
import { DNA } from 'components/DNA';
import { LNTab, LNTabs, TabPanel } from 'components/LNTabs';
import { HeaderCircle } from 'components/HeaderCircle';

import { isDan } from 'nameHelper';

import {
  ActivityLevel,
  DietLevel,
  Fitness,
  Hearth,
  Sleep,
  StressLevel,
  AlcoholSmokingLevel,
} from 'assets/images/imagesList';

export function Insights(props) {
  const { onBack, onContinue, profile, hasDNAFile } = props;
  const [tabValue, setTabValue] = React.useState(0);
  const isMobile = useMediaQuery('(max-width:900px)');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getInsights = () => {
    let insights = [
      {
        front: {
          title: 'Activity level',
          centerValue: (
            <img src={ActivityLevel} alt="illustration for activity level" />
          ),
          value: isDan(profile) ? 'Sedentary' : 'Active',
          color: '#D16A6A',
        },
        back: {
          title: 'Estimated Impact on Statistical life expectancy',
          centerValue: isDan(profile) ? '-2.9' : '+0.9',
          color: isDan(profile) ? '#D16A6A' : '#31B2CF',
          value: isDan(profile) ? 'Less Years of Life' : 'Years of Life',
        },
      },
      {
        front: {
          title: 'BMI',
          centerValue: isDan(profile) ? '28' : '23.1',
          value: isDan(profile) ? 'Significantly Overweight' : 'Active',
          color: isDan(profile) ? '#D16A6A' : '#31B2CF',
        },
        back: {
          title: 'Estimated Impact on Statistical life expectancy',
          centerValue: isDan(profile) ? '-1.7' : '+1.8',
          color: isDan(profile) ? '#D16A6A' : '#ADD429',
          value: isDan(profile) ? 'Less Years of Life' : 'Years of Life',
        },
      },
      {
        front: {
          title: 'Diet',
          centerValue: isDan(profile) ? (
            <img src={DietLevel} alt="illustration for activity level" />
          ) : (
            <img src={DietLevel} alt="illustration for activity level" />
          ),
          value: isDan(profile) ? 'Fair' : 'Vegetarian/Good',
          color: isDan(profile) ? '#D16A6A' : '#31B2CF',
        },
        back: {
          title: 'Estimated Impact on Statistical life expectancy',
          centerValue: isDan(profile) ? '-1.2' : '-1.2',
          color: isDan(profile) ? '#D16A6A' : '#D16A6A',
          value: isDan(profile) ? 'Less Years of Life' : 'Years of Life',
        },
      },
      {
        front: {
          title: 'Sleeping & Stress',
          centerValue: isDan(profile) ? (
            <img src={StressLevel} alt="illustration for activity level" />
          ) : (
            <img src={StressLevel} alt="illustration for activity level" />
          ),
          value: isDan(profile) ? 'Moderate Risk' : 'Medium Risk',
          color: isDan(profile) ? '#D16A6A' : '#31B2CF',
        },
        back: {
          title: 'Estimated Impact on Statistical life expectancy',
          centerValue: isDan(profile) ? '-0.9' : '-0.9',
          color: isDan(profile) ? '#F4AE3E' : '#F4AE3E',
          value: isDan(profile) ? 'Less Years of Life' : 'Years of Life',
        },
      },
      {
        front: {
          title: 'Alcohol/Smoking',
          centerValue: isDan(profile) ? (
            <img
              src={AlcoholSmokingLevel}
              alt="illustration for alcohol/smoking level"
            />
          ) : (
            <img
              src={AlcoholSmokingLevel}
              alt="illustration for alcohol/smoking level"
            />
          ),
          value: isDan(profile) ? 'No Risk' : 'No Risk',
          color: isDan(profile) ? '#D16A6A' : '#D16A6A',
        },
        back: {
          title: 'Estimated Impact on Statistical life expectancy',
          centerValue: isDan(profile) ? '+0.2' : '+0.2',
          color: isDan(profile) ? '#A0CB65' : '#A0CB65',
          value: isDan(profile) ? 'Years of Life' : 'Years of Life',
        },
      },
    ];

    if (!isDan(profile)) {
      insights.push({
        front: {
          title: 'Condition',
          centerValue: isDan(profile) ? null : null,
          value: isDan(profile) ? '' : 'Pregnancy Diabetes',
          color: '',
        },
        back: {
          title: 'Estimated Impact On Statistical Life Expectancy',
          centerValue: '-1.2',
          color: '#D16A6A',
          value: 'Years of Life',
        },
      });
    }

    return insights;
  };

  const getDanFitnessGoals = () => {
    return [
      {
        description: 'Fitness Level',
        value: 1,
      },
      {
        description: 'Average Daily Steps',
        value: '2,800',
      },
      {
        description: 'Average Cardio Activity',
        value: '< 10 min',
      },
      {
        description: 'Average Intensity (1-5)',
        value: '1.8',
      },
    ];
  };

  const getSophieFitnessGoals = () => {
    return [
      {
        description: 'Fitness Level',
        value: 2,
      },
      {
        description: 'Average Daily Steps',
        value: '4,800',
      },
      {
        description: 'Average Cardio Activity',
        value: '< 30 min',
      },
      {
        description: 'Average Intensity (1-5)',
        value: '3',
      },
    ];
  };

  const getDanSleepGoals = () => {
    return [
      {
        description: 'Sleep Quality',
        value: '4.2/5.0',
      },
      {
        description: 'Average Sleep',
        value: '7.8 h',
      },
      {
        description: 'Sleep Interruptions',
        value: '0.2 night',
      },
      {
        description: 'REM Sleep',
        value: '18%',
      },
    ];
  };

  const getSophieSleepGoals = () => {
    return [
      {
        description: 'Sleep Quality',
        value: '3.0/5.0',
      },
      {
        description: 'Average Sleep',
        value: '6 h',
      },
      {
        description: 'Sleep Interruptions',
        value: '0.5 Night',
      },
      {
        description: 'REM Sleep',
        value: '12%',
      },
    ];
  };

  const getDanHearthGoals = () => {
    return [
      {
        description: 'Resting Heart Rate',
        value: '75 bpm',
      },
      {
        description: 'Heart Rate Range',
        value: '85-152 bpm',
      },
      {
        description: 'Heart Rate Variability',
        value: '78 ms',
      },
      {
        description: 'Heart Recovery Factor',
        value: '4',
      },
    ];
  };

  const getSophieHearthGoals = () => {
    return [
      {
        description: 'Resting Heart Rate',
        value: '68 bpm',
      },
      {
        description: 'Heart Rate Range',
        value: '75-132 bpm',
      },
      {
        description: 'Heart Rate Variability',
        value: '78 ms',
      },
      {
        description: 'Heart Recovery Factor',
        value: '4',
      },
    ];
  };

  const getWearableInsights = () => {
    return [
      {
        image: Fitness,
        goal: 'Fitness',
        goalDescription: 'Capability',
        front: (
          <GoalTargets targets={[{ value: 2, description: 'Fitness Level' }]} />
        ),
        back: <GoalTable goals={getDanFitnessGoals()} />,
      },
      {
        image: Sleep,
        goal: 'Sleep',
        goalDescription: 'duration and quality',
        front: (
          <GoalTargets
            targets={[{ value: '+', description: 'Maintain Quality' }]}
          />
        ),
        back: (
          <GoalTable
            goals={isDan(profile) ? getDanSleepGoals() : getSophieSleepGoals()}
          />
        ),
      },
      {
        image: Hearth,
        goal: 'Hearth Health',
        goalDescription: 'Measured by Wearables',
        front: (
          <GoalTargets
            targets={[
              {
                value: '>90+',
                description: 'Heart Rate Variability (HRV)',
              },
              { value: '6', description: 'Heart Recovery Factor (HRF)' },
            ]}
          />
        ),
        back: <GoalTable goals={getDanHearthGoals()} />,
      },
    ];
  };

  const getHealthInsights = () => {
    return [
      {
        image: Fitness,
        goal: 'Fitness',
        front: (
          <HealthTable
            score={61}
            scoreTitle="Health Score"
            scoreText="Needs a little attention"
            healthData={[
              {
                title: '',
                data: [
                  { text: 'You', value: 65 },
                  { text: 'Others', value: 63 },
                ],
              },
            ]}
          />
        ),
        back: (
          <HealthTable
            healthData={[
              {
                title: 'Today',
                data: [
                  { text: 'You', value: 65 },
                  { text: 'Others', value: 63 },
                ],
              },
              {
                title: 'This week',
                data: [
                  { text: 'You', value: 65 },
                  { text: 'Others', value: 71 },
                ],
              },
            ]}
          />
        ),
      },
      {
        image: Sleep,
        goal: 'Sleep',
        front: (
          <HealthTable
            score={61}
            scoreTitle="Health Score"
            scoreText="You are doing great now!"
            healthData={[
              {
                title: '',
                data: [
                  { text: 'You', value: 84 },
                  { text: 'Others', value: 32 },
                ],
              },
            ]}
          />
        ),
        back: (
          <HealthTable
            healthData={[
              {
                title: 'Today',
                data: [
                  { text: 'You', value: 90 },
                  { text: 'Others', value: 32 },
                ],
              },
              {
                title: 'This week',
                data: [
                  { text: 'You', value: 83 },
                  { text: 'Others', value: 42 },
                ],
              },
            ]}
          />
        ),
      },
      {
        image: Hearth,
        goal: 'Nutrition',
        front: (
          <HealthTable
            score={61}
            scoreTitle="Health Score"
            scoreText="You could use some help"
            healthData={[
              {
                title: '',
                data: [
                  { text: 'You', value: 38 },
                  { text: 'Others', value: 68 },
                ],
              },
            ]}
          />
        ),
        back: (
          <HealthTable
            healthData={[
              {
                title: 'Today',
                data: [
                  { text: 'You', value: 38 },
                  { text: 'Others', value: 66 },
                ],
              },
              {
                title: 'This week',
                data: [
                  { text: 'You', value: 42 },
                  { text: 'Others', value: 72 },
                ],
              },
            ]}
          />
        ),
      },
    ];
  };

  const getInfoCircleValues = () => {
    if (isDan(profile) && tabValue === 0) {
      return {
        value: '6.8',
        text: 'More Years of Life',
        description: 'Life Expectancy improvement With Program',
      };
    } else if (isDan(profile) && tabValue === 1) {
      return {
        value: '3',
        text: 'goals',
        description: 'Performance Improvement With Program',
      };
    } else if (!isDan(profile) && tabValue === 0) {
      return {
        value: '8.2',
        text: 'More Years of Life',
        description: 'Life Expectancy improvement With Program',
      };
    } else if (!isDan(profile) && tabValue === 1) {
      return {
        value: '3',
        text: 'goals',
        description: 'Nutrition Improvement With Program',
      };
    }
  };

  const renderCircle = () => {
    return (
      <Grid item xs={12} style={{ marginBottom: 30 }}>
        <Grid
          container
          direction="row"
          justifyContent={'center'}
          alignItems="center"
        >
          <Grid item>
            <HeaderCircle values={getInfoCircleValues()} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Header
        onBack={() => onBack && onBack()}
        title={(isDan(profile) ? 'Dan' : 'Sophie') + '’s Insights'}
        profile={profile}
        infoCircleValues={getInfoCircleValues()}
      />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 55, marginBottom: 85 }}
      >
        <Grid item style={{ maxWidth: '1000px' }}>
          <LNTabs
            value={tabValue}
            onChange={handleChange}
            textColor="#31B2CF"
            indicatorColor="#31B2CF"
            aria-label="insights tabs"
            centered
            variant="fullWidth"
          >
            <LNTab value={0} label="Lifestyle" />
            <LNTab value={1} label={isDan(profile) ? 'Wearable' : 'Health'} />

            {hasDNAFile && <LNTab value={2} label={isMobile ? 'DNA' : 'DNA'} />}
          </LNTabs>

          <Grid item style={{ marginTop: 50 }}>
            <TabPanel value={tabValue} index={0}>
              <Grid
                container
                direction="row"
                justifyContent={isMobile ? 'center' : 'flex-start'}
                alignItems="center"
              >
                {isMobile && renderCircle()}
                {getInsights().map((insight, ix) => {
                  return (
                    <Grid
                      item
                      key={ix}
                      style={{ marginRight: 10, marginTop: 10 }}
                    >
                      <FlippedCardSmall
                        front={
                          <InsightTile
                            title={insight.back.title}
                            centerValue={insight.back.centerValue}
                            value={insight.back.value}
                            color={insight.back.color}
                          />
                        }
                        back={
                          <InsightTile
                            title={insight.front.title}
                            centerValue={insight.front.centerValue}
                            value={insight.front.value}
                            color={insight.front.color}
                          />
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Grid
                container
                direction="row"
                justifyContent={isMobile ? 'center' : 'flex-start'}
                alignItems="center"
              >
                {isMobile && renderCircle()}
                {isDan(profile) &&
                  getWearableInsights().map((insight, ix) => {
                    return (
                      <Grid
                        item
                        key={ix}
                        style={{ marginRight: 10, marginTop: 10 }}
                      >
                        <WearableTile
                          image={insight.image}
                          goal={insight.goal}
                          goalDescription={insight.goalDescription}
                          front={insight.front}
                          back={insight.back}
                        />
                      </Grid>
                    );
                  })}
                {!isDan(profile) &&
                  getHealthInsights().map((insight, ix) => {
                    return (
                      <Grid
                        item
                        key={ix}
                        style={{ marginRight: 10, marginTop: 10 }}
                      >
                        <HealthTile
                          image={insight.image}
                          goal={insight.goal}
                          goalDescription={insight.goalDescription}
                          front={insight.front}
                          back={insight.back}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <DNA profile={profile} />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>

      <ContinueButtonSection onContinue={() => onContinue && onContinue()} />
    </div>
  );
}
