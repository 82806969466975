import { Grid } from '@mui/material';

export function GoalTable(props) {
  const { goals } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {goals.map((goal, ix) => {
        return (
          <Grid item style={{ fontSize: 14, marginTop: 5 }} key={ix}>
            <Grid container direction="row">
              <Grid item style={{ width: 167 }}>
                {goal.description}
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                {goal.value}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
