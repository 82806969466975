import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { FlipIndicator } from 'assets/images/imagesList';

export function FlippableGoals(props) {
  const { front, back } = props;
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <Grid
      container
      onClick={() => setIsFlipped(!isFlipped)}
      justifyContent="flex-end"
      alignItems="flex-end"
      style={{
        width: '260px',
        height: '200px',
        perspective: '1000',
        backgroundColor: 'white',
        fontFamily: 'Museo500',
      }}
    >
      <Grid
        item
        xs={11}
        style={{
          transform: isFlipped ? 'rotateY(180deg)' : 'none',
          position: 'relative',
          height: '180px',
          transition: 'transform 0.6s',
          transformStyle: 'preserve-3d',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            webkitBackfaceVisibility: 'hidden',
            backfaceVisibility: 'hidden',
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          {front}
        </div>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            webkitBackfaceVisibility: 'hidden',
            backfaceVisibility: 'hidden',
            backgroundColor: 'white',
            color: 'black',
            transform: 'rotateY(180deg)',
          }}
        >
          {back}
        </div>
      </Grid>
      <Grid item xs={1} style={{ paddingLeft: 10 }}>
        <img
          src={FlipIndicator}
          height={20}
          alt="illustration for flip indicator"
        />
      </Grid>
    </Grid>
  );
}
