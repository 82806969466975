import { Grid, useMediaQuery } from '@mui/material';

export function AnswersList(props) {
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      justifyContent="center"
      alignItems="flex-start"
    >
      {props.data.map((answersItem, ix) => {
        return (
          <Grid item style={{ width: 300, marginTop: 40 }} key={'i' + ix}>
            <div style={{ marginBottom: 10 }}>
              <span style={{ fontSize: 25 }}>{answersItem.title}</span>
            </div>
            <Grid container direction="column">
              {answersItem.answers?.map((factor, ix) => {
                return (
                  <Grid item key={'f' + ix} style={{ marginTop: 15 }}>
                    <Grid container direction="row">
                      <Grid item style={{ width: factor.value && 100 }}>
                        {factor.name}
                      </Grid>
                      {factor.value && <Grid item>{factor.value}</Grid>}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
