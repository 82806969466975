import {
  HighPredisposition,
  HighRisk,
  LowPredisposition,
  LowRisk,
  MediumPredisposition,
  MediumRisk,
} from 'assets/images/imagesList';
import { isDan } from 'nameHelper';
import { DNAReport } from './DNAReport';

export function DNA(props) {
  const { profile } = props;

  const getDanRiskyTraits = () => {
    return [
      {
        name: 'Achilles Tendinopathy',
        image: HighRisk,
      },
      {
        name: 'Anterior Cruciate Ligament Rupture',
        image: MediumRisk,
      },
      {
        name: 'Overall Tendon and Ligament Injury Risk',
        image: LowRisk,
      },
    ];
  };

  const getSophieRiskyTraits = () => {
    return [
      {
        name: 'Vitamin D difficiency',
        image: HighRisk,
      },
      {
        name: 'Vitamin E difficiency',
        image: MediumRisk,
      },
      {
        name: 'Vitamin B6 difficiency',
        image: LowRisk,
      },
      {
        name: 'Magnesium',
        image: LowRisk,
      },
      {
        name: 'Iron',
        image: LowRisk,
      },
    ];
  };

  const getDanPredispositionTraits = () => {
    return [
      {
        name: 'Endurance',
        image: HighPredisposition,
      },
      {
        name: 'Elite Endurance',
        image: MediumPredisposition,
      },
      {
        name: 'Aerobic Performance',
        image: LowPredisposition,
      },
      {
        name: 'Lean Body Mass Potential',
        image: LowPredisposition,
      },
      {
        name: 'Power',
        image: LowPredisposition,
      },
    ];
  };

  const getSophiePredispositionTraits = () => {
    return [
      {
        name: 'Caffeine Sensitivity',
        image: HighPredisposition,
      },
      {
        name: 'Sugar Craving',
        image: MediumPredisposition,
      },
      {
        name: 'Mediterranean Diet Effectiveness',
        image: LowPredisposition,
      },
      {
        name: 'Low-carb Diet Effectiveness',
        image: LowPredisposition,
      },
    ];
  };

  const getDanReport = () => {
    return [
      {
        name: 'Fitness Profile',
        risks: 3,
        strengths: 4,
        riskyTraits: getDanRiskyTraits(),
        strengthTraits: getDanPredispositionTraits(),
      },
      {
        name: 'Fitness Health Benefits',
        risks: 11,
        strengths: 2,
        riskyTraits: getDanRiskyTraits(),
        strengthTraits: getDanPredispositionTraits(),
      },
      {
        name: 'Muscle Characteristics',
        risks: 13,
        strengths: 7,
        riskyTraits: getDanRiskyTraits(),
        strengthTraits: getDanPredispositionTraits(),
      },
    ];
  };

  const getSophieReport = () => {
    return [
      {
        name: 'Vitamins & Minerals',
        risks: 5,
        strengths: 4,
        riskyTraits: getSophieRiskyTraits(),
        strengthTraits: getSophiePredispositionTraits(),
      },
      {
        name: 'Optimal Diet & Metabolism',
        risks: 11,
        strengths: 2,
        riskyTraits: getSophieRiskyTraits(),
        strengthTraits: getSophiePredispositionTraits(),
      },
      // {
      //   name: 'Muscle Characteristics',
      //   risks: 13,
      //   strengths: 7,
      //   riskyTraits: getSophieRiskyTraits(),
      //   strengthTraits: getSophiePredispositionTraits(),
      // },
    ];
  };

  return (
    <DNAReport reports={isDan(profile) ? getDanReport() : getSophieReport()} />
  );
}
