import { Grid } from '@mui/material';
import { ArrowRight } from 'assets/images/imagesList';

export function ContinueButton(props) {
  const { onClick, disabled } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        width: '100%',
        cursor: disabled ? 'context-menu' : 'pointer',
        opacity: disabled ? 0.3 : 1,
      }}
      onClick={() => onClick && !disabled && onClick()}
    >
      <Grid
        item
        style={{
          fontSize: 30,
          marginRight: 30,
          fontFamily: 'Museo700',
        }}
      >
        CONTINUE
      </Grid>
      <Grid item>
        <img
          src={ArrowRight}
          width="17"
          height="34"
          alt="illustration for next"
        />
      </Grid>
    </Grid>
  );
}
