import React, { useState } from "react";
import { Grid } from "@mui/material";

export function FlippedCardSmall(props) {
  const { front, back } = props;
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <Grid
      container 
      onClick={() => setIsFlipped(!isFlipped)}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-end"
      style= {{
        width: "300px",
        height: "260px",
        perspective: "1000",
        borderRadius: 20,
        border: "1px solid #ececec",
        backgroundColor: "white",
        padding: 30,
        cursor: "pointer",
      }}
    >
      <Grid
        item
        style={{ 
          transform: isFlipped ? "none" : "rotateY(180deg)",
          position: "relative",
          width: "100%",
          height: "230px",
          transition: "transform 0.6s",
          transformStyle: "preserve-3d",
        }}>
        <div 
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            webkitBackfaceVisibility: "hidden",
            backfaceVisibility: "hidden",
            backgroundColor: "white",
            color: "black",
          }}
        >
          {front}
        </div>
        <div 
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            webkitBackfaceVisibility: "hidden",
            backfaceVisibility: "hidden",
            backgroundColor: "white",
            color: "black",
            transform: "rotateY(180deg)"
          }}
        >
          {back}
        </div>
      </Grid>
    </Grid>
  );
}