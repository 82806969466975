import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { AppleHealth, GoogleFit } from 'assets/images/imagesList';
import { ContinueButtonSection } from 'components/ContinueButtonSection';
import { Header } from 'components/Header';
import { isDan } from 'nameHelper';

export function Wearables(props) {
  const { onBack, onContinue, wearableToSelected, profile } = props;
  const [selectedWearable, setSelectedWearable] = useState(wearableToSelected);
  const isMobile = useMediaQuery('(max-width:900px)');

  const onWearableSelected = (wearable) => {
    setSelectedWearable(wearable);
  };

  return (
    <div>
      <Header
        onBack={() => onBack && onBack()}
        title="Connecting Wearables for Baseline"
        additionalText=""
        profile={profile}
      />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 55, marginBottom: 85 }}
      >
        <Grid item>
          <Grid container direction="column">
            <Grid
              item
              style={{ fontSize: 45, fontFamily: 'Museo500', padding: 20 }}
            >
              Connect your wearable devices
            </Grid>
            <Grid
              item
              style={{
                maxWidth: isMobile ? '100vw' : 593,
                fontSize: 24,
                padding: 20,
              }}
            >
              {isDan(profile)
                ? 'In this step, Dan can choose which wearable data app if any he wants to use to establish his baseline. To do so we need the latest 72 hours of data for his activity, heart rate and sleep from the app.'
                : 'In this step, Sophie can choose which wearable data app if any she wants to use to establish her baseline. To do so we need the latest 72 hours of data for his activity, heart rate and sleep from the app.'}
            </Grid>
            <Grid
              item
              style={{
                maxWidth: isMobile ? '100vw' : 593,
                fontSize: 24,
                marginTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              {isDan(profile)
                ? 'He can also use his SmartPhone to leverage our Spren Vitals integration to assess his fitness level after a 30 minute workout.'
                : 'Sophie can also activate the Fingerscan™ feature on her smartphone app.                '}
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: 50 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: isMobile ? 0 : 55 }}
          >
            <Grid item style={{ fontSize: 25, fontFamily: 'Museo700' }}>
              Choose an App to connect
            </Grid>
            <Grid
              item
              style={{
                maxWidth: isMobile ? '100vw' : 450,
                fontSize: 24,
                marginTop: 20,
              }}
            >
              <Grid container direction={isMobile ? 'column' : 'row'}>
                <Grid
                  item
                  style={{
                    width: 184,
                    height: 204,
                    marginLeft: 13,
                    marginRight: 13,
                    boxShadow: '1px 2px 2px 1px #ececec',
                    borderRadius: 10,
                    border:
                      selectedWearable === 'apple'
                        ? '2px Solid #31B2CF'
                        : 'none',
                    cursor: 'pointer',
                    marginTop: 10,
                  }}
                  onClick={() => {
                    onWearableSelected('apple');
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <img
                        src={AppleHealth}
                        alt="illustration for Apple Health"
                        style={{ marginTop: 20 }}
                      />
                    </Grid>
                    <Grid item style={{ fontSize: 16 }}>
                      Apple Health
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    width: 184,
                    height: 204,
                    marginLeft: 13,
                    marginRight: 13,
                    boxShadow: '1px 2px 2px 1px #ececec',
                    borderRadius: 10,
                    border:
                      selectedWearable === 'google'
                        ? '2px Solid #31B2CF'
                        : 'none',
                    cursor: 'pointer',
                    marginTop: 10,
                  }}
                  onClick={() => {
                    onWearableSelected('google');
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <img
                        src={GoogleFit}
                        alt="illustration for GoogleFit"
                        style={{ marginTop: 50 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ContinueButtonSection
        disabled={!selectedWearable}
        onContinue={() => selectedWearable && onContinue && onContinue()}
      />
    </div>
  );
}
