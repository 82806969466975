import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { VideoPlay } from 'assets/images/imagesList';

export function ProgramVideos(props) {
  const { videos } = props;
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Grid container direction="row">
      {videos.map((video, ix) => {
        return (
          <Grid
            item
            xs={isMobile ? 12 : 4}
            key={ix}
            style={{ marginBottom: 50 }}
          >
            <Grid
              container
              direction="column"
              style={{
                paddingLeft: isMobile ? 5 : ix !== 0 ? 15 : 0,
                paddingRight: isMobile ? 5 : 15,
              }}
            >
              <Grid
                item
                style={{
                  fontSize: 24,
                  minHeight: !isMobile && 50,
                  display: !isMobile && 'flex',
                  flexDirection: !isMobile && 'column-reverse',
                  lineHeight: '25px',
                  fontWeight: isMobile ? 500 : 300,
                  textAlign: isMobile && 'center',
                  marginBottom: 20,
                }}
              >
                {video.title}
              </Grid>
              <Grid
                item
                style={{
                  marginTop: 5,
                  height: 200,
                  backgroundImage: `url(${video.image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  borderRadius: 10,
                  cursor: 'pointer',
                }}
                onClick={() => window.open(video.link, '_blank')}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <img
                      src={VideoPlay}
                      alt="video play icon"
                      style={{
                        width: 50,
                        height: 44,
                        marginTop: 75,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: 5, paddingLeft: isMobile && 10 }}>
                <Grid
                  container
                  direction="column"
                  style={{ fontSize: 18, marginTop: 5 }}
                >
                  <Grid item style={{ fontFamily: 'Museo700' }}>
                    Recommended for:
                  </Grid>
                  {video.recommendations.map((rec, ix) => {
                    return (
                      <Grid
                        item
                        key={'rec' + ix}
                        style={{ fontFamily: 'Museo300', marginTop: 5 }}
                      >
                        {rec}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
